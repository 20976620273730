import React, { useState, useEffect } from "react";

import WmsModel from "./../react/components/WmsModel/WmsModel";
import { StringWms } from "../../assets/ts/StringWms";
import WmsCollapse from "./../react/components/WmsCollapse/WmsCollapse";
import WmsDropdown from "./../react/components/WmsDropdown/WmsDropdown";
import WmsInput from "./../react/components/WmsInput/WmsInput";
import WmsTextArea from "./../react/components/WmsTextArea/WmsTextArea";
import WmsDateTimePicker from "./../react/components/WmsDateTimePicker/WmsDateTimePicker";
import WmsButton from "./../react/components/WmsButton/WmsButton";
import WmsRadio from "./../react/components/WmsRadio/WmsRadio";
import WmsFormGrid from "./../react/components/WmsFormGrid/WmsFormGrid";
import * as GlobalService from "./../react/global_services";
import WmsDropdownList from "./../react/components/WmsDropdownList/WmsDropdownList.jsx";
import WmsComboBox from "./../react/components/WmsComboBox/WmsComboBox.jsx";
import WmsCheckBox from "./../react/components/WmsCheckBox/WmsCheckBox.jsx";
import * as GridConfig from "./adjust-inventory-grid-configuration";
import SelectReceiveItemModel from "./select-receive-item.model.component";
import * as AdjustInventoryServices from "./adjust-inventory.services";
import WmsGrid from "../react/components/WmsGrid/WmsGrid.jsx";
import { Big } from "../react/global_variable_service";
import { ObjectWms } from "../../assets/ts/ObjectWms";
import { CreateManualChargeModel } from "../react/orders/findOrders/model/create-manual-charge-model.component.jsx";

import _ from "lodash";

import "./adjustInventory.scss";
declare var window: any;
const { $ } = window;

let selectedCustomerId = 0;
interface Props {
    onCloseCreateNewInventoryAdjustmentModal: any;
    isOpenCreateNewInventoryAdjustmentModal: boolean;
    wmsSelector: string;
    selectedRow: any;
    windowId: string;
    onApplyCreateAdjustment: any;
    applyingAdjustment: boolean;
}

let defaultAdjustmentItem = {
    rowIndex: 0,
    type: "add",
    AutoSelectInventory: false,
    SKU: "",
    ItemIdentifierId: 0,
    Qualifier: null,
    Qty: 0,
    LotNumber: "",
    SerialNumber: "",
    ExpirationDate: "",
    LandedCost: "",
    LocationId: null,
    Location: "",
    LocationName: "",
    MovableUnitLabel: "",
    SplitLineByMovableUnit: false,
    reduceInventoryObject: [],
};

const defaultItemTrackByDetail = {
    TrackLotNumber: 0,
    TrackSerialNumber: 0,
    TrackExpirationDate: 0,
    TrackCost: 0,
};

const CreateNewInventoryAdjustmentModal: React.FC<Props> = (
    props: Props
    // eslint-disable-next-line sonarjs/cognitive-complexity
): any => {
    const CustomLabels = GlobalService.getCustomLabels();
    const CustomerOptions = GlobalService.getCustomerList(true);
    const adjustmentItemsGridColumns = GridConfig.getAdjustmentItemsGridColumns();
    const adjustmentItemsGridSchema = useState(GridConfig.getAdjustmentItemsGridSchema());
    const CalculateCharge_GrideHeaders = StringWms.convertSringToOption(
        window.translate("Orders_ManageOrders_Grid_Headers_Applied_Charges")
    );
    const chargesGridColumns = GridConfig.ChargesColumns(CalculateCharge_GrideHeaders, window.translate);
    const chargesGridSchema = GridConfig.getCalculateChargesGridSchema().schema;
    const [isOpenManualChargeModal, setIsOpenManualChargeModal] = useState(false);
    const [isOpenConfirmModel, setIsOpenConfirmModel] = useState(false);

    const [trackLocation, setTrackLocation] = useState(0);
    const [muTrackPallats, setMuTrackPallats] = useState(0);
    const [facilityOPtions, setFacilityOptions] = useState(GlobalService.getFacilityList(true));
    const [qualifiersOptions, setQualifiersOptions] = useState([]);
    const [itemTrackByDetail, setItemTrackByDetail] = useState(defaultItemTrackByDetail);
    const [isManualCharge, setIsManualCharge] = useState(false);
    const [isManualChargeLoading, setIsManualChargeLoading] = useState(false);

    const [adjustInventoryData, setAdjustInventoryData] = useState(props.selectedRow);
    const [currentAdjustmentItem, setCurrentAdjustmentItem] = useState(defaultAdjustmentItem);
    const [itemIsIntegral, setItemIsIntegral] = useState(true);
    const [rowIndex, setRowIndex] = useState(0);
    const [optionsMovableUnitLabels, setOptionsMovableUnitLabels] = useState([
        {
            Name: "System Assigned",
            Value: window.translate("Label_System_Assigned"),
        },
        {
            Name: "None",
            Value: window.translate("Label_None"),
        },
    ]);

    const [isOpenSelectReceiveItemModel, setIsOpenSelectReceiveItemModel] = useState(false);

    const [isIncludeChargeShow, setIsIncludeChargeShow] = useState(false);
    const [chargesType, setChargesType] = useState([]);
    const [totalSum, setTotalSum] = useState("");
    const [availableReceiveItemData, setAvailableReceiveItemData] = useState({});
    const [showMessageNoReceiveItemAsFilter, setShowMessageNoReceiveItemAsFilter] = useState(false);
    const [loadingReceiveItems, setLoadingReceiveItems] = useState(false);
    const rights = GlobalService.GetRights();

    useEffect(() => {
        let tmpTotalSum = 0;
        if (adjustInventoryData.Billing) {
            adjustInventoryData.Billing = adjustInventoryData.Billing.map((item, index) => {
                item.rowId = index;
                return item;
            });
        }
        for (let i = 0; i < adjustInventoryData.Billing.length; i++) {
            const billing = adjustInventoryData.Billing[i];
            tmpTotalSum = tmpTotalSum + parseFloat(billing.TotalCharges);
        }

        let formatTotalSum = tmpTotalSum
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","); // eslint-disable-line security/detect-unsafe-regex
        setTotalSum(formatTotalSum);

        $(document).off("click", ".remove-adjustment-item");
        $(document).on("click", ".remove-adjustment-item", function () {
            var rowIndex = parseInt($(this).data("id"));
            var newAdjustmentItems = adjustInventoryData.AdjustmentItems.filter((i) => i.rowIndex !== rowIndex);
            setAdjustInventoryData((prevState: any) => ({
                ...prevState,
                AdjustmentItems: newAdjustmentItems,
            }));
            return false;
        });
    }, [adjustInventoryData, rowIndex]);

    const handleChangeInventoryData = (event: any) => {
        let { name, value } = event.target;
        var finalValue = value;
        if (name === "CustomerId") {
            name = "CustomerIdentifier";
            finalValue = { Id: parseInt(value) };
            selectedCustomerId = event.target.value;
            handleCustomerChange(parseInt(event.target.value));
            setAdjustInventoryData((prevState: any) => ({
                ...prevState,
                [name]: finalValue,
            }));
            console.log("vishal default", 0);
            return false;
        } else if (name === "FacilityID") {
            name = "FacilityIdentifier";
            finalValue = { Id: parseInt(value) };
        } else if (name === "CreateInvoice" || name === "IncludeCharges" || name === "AutoSelectInventory") {
            console.log("AutoSelectInventory", event.target.checked);
            finalValue = event.target.checked;
        }

        setAdjustInventoryData((prevState: any) => ({
            ...prevState,
            [name]: finalValue,
        }));
    };
    const handleCustomerChange = (CustomerID: any) => {
        var selectedCustomer = CustomerOptions.find((cus: any) => CustomerID !== 0 && cus.id === CustomerID);
        if (selectedCustomer) {
            var customerFacilityIds = selectedCustomer.facilities;
            let allFacilities = GlobalService.getFacilityList(true);
            allFacilities = allFacilities.filter((f: any) => customerFacilityIds.includes(f.id) || f.id === 0);
            setFacilityOptions(allFacilities);
            let selectedFacId = 0;
            if (allFacilities.length == 2) {
                selectedFacId = allFacilities[1].id;
            }
            setAdjustInventoryData((prevState: any) => ({
                ...prevState,
                FacilityIdentifier: { Id: selectedFacId },
            }));
            if (!StringWms.isEmpty(selectedCustomer.options.trackPallets)) {
                setMuTrackPallats(selectedCustomer.options.trackPallets);
            }
            setTrackLocation(selectedCustomer.options.trackLocation);
        } else {
            setFacilityOptions([]);
        }
        resetSkuDropdownOptions();
    };
    const resetSkuDropdownOptions = () => {
        setItemTrackByDetail(defaultItemTrackByDetail);
        setQualifiersOptions([]);
        setCurrentAdjustmentItem((prevState: any) => ({
            ...prevState,
            ItemIdentifierId: 0,
            SKU: "",
            Qualifier: "",
        }));
    };
    const handleAdjustmentType = (value) => {
        setCurrentAdjustmentItem((prevState: any) => ({
            ...prevState,
            type: value,
        }));
        defaultAdjustmentItem.type = value;
    };

    const changecurrentAdjustmentItemSku = (event) => {
        if (event.dataItem !== undefined && event.dataItem.Id !== "") {
            var qualifierOptions = _.cloneDeep(event.dataItem.QualifierSummaries);
            if (qualifierOptions.filter((el) => el.Qualifier !== "").length > 0) {
                if (qualifierOptions.find((el) => el.Qualifier === "") === undefined && qualifierOptions.length) {
                    qualifierOptions.unshift({ Qualifier: "" });
                }
                setQualifiersOptions(qualifierOptions);
            } else {
                setQualifiersOptions([]);
            }
            setItemIsIntegral(event.dataItem.PrimaryIsIntegral);
            setItemTrackByDetail(event.dataItem.TrackBys);
            setCurrentAdjustmentItem((prevState: any) => ({
                ...prevState,
                ItemIdentifierId: event.dataItem.Id,
                SKU: event.dataItem.Sku,
                Qualifier: "",
            }));
        } else {
            resetSkuDropdownOptions();
        }
    };

    const changecurrentAdjustmentItem = (event) => {
        let { name, value } = event.target;
        if (name === "SplitLineByMovableUnit") {
            value = !currentAdjustmentItem.SplitLineByMovableUnit;
            setCurrentAdjustmentItem((prevState: any) => ({
                ...prevState,
                [name]: value,
            }));
        } else if (name === "LocationId") {
            setCurrentAdjustmentItem((prevState: any) => ({
                ...prevState,
                [name]: value.Id,
                Location: value.Name,
            }));
        } else if (name === "Qty") {
            setCurrentAdjustmentItem((prevState: any) => ({
                ...prevState,
                [name]: itemIsIntegral ? parseInt(value) : value,
            }));
        } else {
            setCurrentAdjustmentItem((prevState: any) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    const formatDate = (dateValue, time) => {
        if (dateValue && time) {
            var formatDate = kendo.parseDate(dateValue);
            let formatArr = time.split(":");
            formatDate.setHours(formatArr[0]);
            formatDate.setMinutes(formatArr[1]);
            formatDate.setSeconds(formatArr[2]);
            return StringWms.convertFilterStringToISODate(formatDate.toString(), "", false, true).isoStringUTC;
        }
        return dateValue;
    };

    const createAdjustInventoryItem = async () => {
        if (currentAdjustmentItem.type === "reduce") {
            setLoadingReceiveItems(true);
            var filterData = {};
            if (adjustInventoryData.AutoSelectInventory) {
                filterData = {
                    "CustomerId": adjustInventoryData.CustomerIdentifier.Id,
                    "FacilityId": adjustInventoryData.FacilityIdentifier.Id,
                    "Sku": currentAdjustmentItem.SKU,
                    "ItemId": currentAdjustmentItem.ItemIdentifierId,
                    "Qty": currentAdjustmentItem.Qty,
                    "Qualifier": currentAdjustmentItem.Qualifier,
                    "LotNumber": currentAdjustmentItem.LotNumber,
                    "SerialNumber": currentAdjustmentItem.SerialNumber,
                    "ExpirationDate": currentAdjustmentItem.ExpirationDate,
                };
                AdjustInventoryServices.ProposedAdjustmentAllocations(filterData).then((res) => {
                    setLoadingReceiveItems(false);
                    var finalData = res.data;
                    finalData.Candidates.map((el) => {
                        el.LocationName = el.LocationIdentifier?.NameKey?.Name;
                        el.MovableUnitLabel = el.PalletIdentifier?.NameKey?.Name;
                        el.ReceiptDate = el.ReceivedDate;
                        el.PrimaryAvailable = el.AvailableQuantity;
                        return el;
                    });
                    finalData.ProposedAllocations.map((PAItem) => {
                        let selectedItem = finalData.Candidates.filter(
                            (el) => el.ReceiveItemId == PAItem.ReceiveItemId
                        );
                        PAItem.LocationName = selectedItem[0].LocationName;
                        PAItem.MovableUnitLabel = selectedItem[0].MovableUnitLabel;
                        PAItem.ReceiverId = selectedItem[0].ReceiverId;
                        PAItem.Allocated = PAItem.Qty;
                        return PAItem;
                    });
                    if (finalData.Candidates.length > 0) {
                        setAvailableReceiveItemData(finalData);
                        setIsOpenSelectReceiveItemModel(true);
                        setShowMessageNoReceiveItemAsFilter(false);
                    } else {
                        setAvailableReceiveItemData([]);
                        setShowMessageNoReceiveItemAsFilter(true);
                    }
                });
            } else {
                filterData = {
                    CustomerId: adjustInventoryData.CustomerIdentifier.Id,
                    FacilityId: adjustInventoryData.FacilityIdentifier.Id,
                    Sku: currentAdjustmentItem.SKU,
                    Qualifier: currentAdjustmentItem.Qualifier,
                    LotNumber: currentAdjustmentItem.LotNumber,
                    SerialNumber: currentAdjustmentItem.SerialNumber,
                    Location:
                        currentAdjustmentItem.Location === window.translate("Label_Preferred_Location_Text")
                            ? ""
                            : currentAdjustmentItem.Location,
                    MuLabel:
                        currentAdjustmentItem.MovableUnitLabel === "system assigned"
                            ? ""
                            : currentAdjustmentItem.MovableUnitLabel,
                    StartDate: formatDate(currentAdjustmentItem.ExpirationDate, "0:0:0"),
                    EndDate: formatDate(currentAdjustmentItem.ExpirationDate, "23:59:59"),
                    IndexOptionsDate: 1,
                    IndexOptionsSku: 2,
                };

                AdjustInventoryServices.getReceiveItemGridData(filterData)
                    .then((res) => {
                        // var finalData = res.data.Data;
                        var finalData = {
                            Candidates: res.data.Data.filter((e) => e.PrimaryAvailable !== 0),
                            ProposedAllocations: [],
                        };
                        if (currentAdjustmentItem.MovableUnitLabel === "system assigned") {
                            finalData = finalData.Candidates.filter((e) => e.MovableUnitLabel !== null);
                        }
                        if (finalData.Candidates.length > 0) {
                            setAvailableReceiveItemData(finalData);
                            setIsOpenSelectReceiveItemModel(true);
                            setShowMessageNoReceiveItemAsFilter(false);
                        } else {
                            setAvailableReceiveItemData([]);
                            setShowMessageNoReceiveItemAsFilter(true);
                        }
                    })
                    .finally(() => {
                        setLoadingReceiveItems(false);
                    });
            }
        } else {
            var adjustmentItem = currentAdjustmentItem;
            let loc = currentAdjustmentItem.Location;
            if (!loc || loc == "Select a location") {
                loc = null; // passing it as null if its blank also
            }
            var isMuLabelNull = false;
            if (
                !adjustmentItem.MovableUnitLabel ||
                !adjustmentItem.MovableUnitLabel.trim() ||
                adjustmentItem.MovableUnitLabel == "None" ||
                adjustmentItem.MovableUnitLabel.toLowerCase() ==
                    window.translate("Label_System_Assigned").toLowerCase() ||
                adjustmentItem.MovableUnitLabel.toLowerCase() == "systemset"
            ) {
                isMuLabelNull = true;
            }
            const applyAdj = function () {
                adjustmentItem.rowIndex = rowIndex;
                AddAdjustInventoryRowData(adjustmentItem);
                var movableOptionIndex = optionsMovableUnitLabels.findIndex(
                    (ele) => ele.Name === adjustmentItem.MovableUnitLabel
                );
                if (movableOptionIndex === -1) {
                    setOptionsMovableUnitLabels((ps) => [
                        ...ps,
                        { Name: adjustmentItem.MovableUnitLabel, Value: adjustmentItem.MovableUnitLabel },
                    ]);
                }
            };
            if (!isMuLabelNull) {
                AdjustInventoryServices.checkifMUExistsMultipleLocs(
                    adjustmentItem.MovableUnitLabel.trim(),
                    adjustInventoryData.FacilityIdentifier.Id,
                    loc,
                    null
                )
                    .then((response) => {
                        if (response != "undefined") {
                            applyAdj();
                        }
                    })
                    .finally(() => {});
            } else {
                applyAdj();
            }
        }
    };
    const addReduceInventoryAdjustmentItems = async (selectedReceivedItemData) => {
        var adjustmentItem = currentAdjustmentItem;
        adjustmentItem.rowIndex = rowIndex;
        adjustmentItem.reduceInventoryObject = selectedReceivedItemData;
        if (selectedReceivedItemData.length > 1) {
            var firstMatch = selectedReceivedItemData[0].LotNumber;
            adjustmentItem.LotNumber =
                selectedReceivedItemData.filter((el) => el.LotNumber != firstMatch).length >= 1
                    ? "multiple"
                    : firstMatch;

            firstMatch = selectedReceivedItemData[0].SerialNumber;
            adjustmentItem.SerialNumber =
                selectedReceivedItemData.filter((el) => el.SerialNumber != firstMatch).length >= 1
                    ? "multiple"
                    : firstMatch;

            firstMatch = selectedReceivedItemData[0].LocationName;
            adjustmentItem.Location =
                selectedReceivedItemData.filter((el) => el.LocationName != firstMatch).length >= 1
                    ? "multiple"
                    : firstMatch;

            firstMatch = selectedReceivedItemData[0].ExpirationDate;
            adjustmentItem.ExpirationDate =
                selectedReceivedItemData.filter((el) => el.ExpirationDate != firstMatch).length >= 1
                    ? "multiple"
                    : firstMatch;

            firstMatch = selectedReceivedItemData[0].MovableUnitLabel;
            adjustmentItem.MovableUnitLabel =
                selectedReceivedItemData.filter((el) => el.MovableUnitLabel != firstMatch).length >= 1
                    ? "multiple"
                    : firstMatch;
        } else {
            adjustmentItem.LotNumber = selectedReceivedItemData[0].LotNumber;
            adjustmentItem.SerialNumber = selectedReceivedItemData[0].SerialNumber;
            adjustmentItem.Location = selectedReceivedItemData[0].LocationName;
            adjustmentItem.ExpirationDate = selectedReceivedItemData[0].ExpirationDate;
            adjustmentItem.MovableUnitLabel = selectedReceivedItemData[0].MovableUnitLabel;
        }

        AddAdjustInventoryRowData(adjustmentItem);
        onCloseSelectReceiveItemModel();
    };
    const AddAdjustInventoryRowData = (adjustmentItem) => {
        setAdjustInventoryData((prevState: any) => ({
            ...prevState,
            AdjustmentItems: [...prevState.AdjustmentItems, adjustmentItem],
        }));

        setCurrentAdjustmentItem({
            ...defaultAdjustmentItem,
            type: adjustmentItem.type,
            AutoSelectInventory: adjustmentItem.AutoSelectInventory,
        });
        if ($("#ExpirationDate").data("kendoDateTimePicker") !== undefined) {
            $("#ExpirationDate").data("kendoDateTimePicker").value("");
        }

        setRowIndex(adjustmentItem.rowIndex + 1);
        setQualifiersOptions([]);
    };
    const onBoundAdjustInventoryItemGrid = () => {
        var grid = $("#AdjustInventoryItemGrid").data("kendoGrid");
        var gridData = grid.dataSource.view();
        for (var i = 0; i < gridData.length; i++) {
            if (gridData[i].type === "reduce") {
                grid.table
                    .find("tr[data-uid='" + gridData[i].uid + "']")
                    .addClass("adjust-inventory-item-grid-reduce-row");
            }
        }
    };

    const onCloseSelectReceiveItemModel = () => {
        setIsOpenSelectReceiveItemModel(false);
    };

    const nextIncludeCharge = (flag: boolean) => {
        setIsIncludeChargeShow(flag);
        if (flag) {
            calculateCharges(loadPredefinedCharges, []);
        }
    };
    const allValidationDone = () => {
        var isvalid =
            adjustInventoryData.AdjustmentItems.length < 1 ||
            adjustInventoryData.CustomerIdentifier.Id === 0 ||
            adjustInventoryData.FacilityIdentifier.Id === 0 ||
            adjustInventoryData.ReferenceNum === "";
        if (adjustInventoryData.IncludeCharges && !isIncludeChargeShow) {
            return isvalid || !adjustInventoryData.IncludeCharges;
        }
        return isvalid;
    };
    const isCompletePrimaryData = () => {
        return (
            adjustInventoryData.CustomerIdentifier.Id === 0 ||
            adjustInventoryData.FacilityIdentifier.Id === 0 ||
            adjustInventoryData.ReferenceNum === ""
        );
    };
    const createAdjustment = () => {
        var formData = {
            ReferenceNum: adjustInventoryData.ReferenceNum,
            CreateInvoice: adjustInventoryData.CreateInvoice,
            InvoiceCreationInfo: {
                SetInvoiceDate: true,
                UtcOffset: (new Date().getTimezoneOffset() / 60) * -1,
            },
            Notes: adjustInventoryData.Notes,
            PONumber: adjustInventoryData.PONumber,
            CustomerIdentifier: {
                Id: adjustInventoryData.CustomerIdentifier.Id,
            },
            FacilityIdentifier: {
                Id: adjustInventoryData.FacilityIdentifier.Id,
            },
            Billing: [],
            AdjustmentItems: [],
        };

        adjustInventoryData.AdjustmentItems.map((element) => {
            if (element.type === "reduce") {
                element.reduceInventoryObject.map((bilObj) => {
                    formData.AdjustmentItems.push({
                        Qty: -1 * bilObj.Allocated,
                        ReceiveItemId: bilObj.ReceiveItemId,
                    });
                    return false;
                });
            } else {
                formData.AdjustmentItems.push({
                    Qty: element.Qty,
                    MovableUnitLabel: element.MovableUnitLabel,
                    SplitLineByMovableUnit: element.SplitLineByMovableUnit,
                    ItemIdentifierId: element.ItemIdentifierId,
                    Qualifier: element.Qualifier !== "" ? element.Qualifier : null,
                    LotNumber: element.LotNumber,
                    SerialNumber: element.SerialNumber,
                    ExpirationDate: element.ExpirationDate,
                    LocationId: element.LocationId !== 0 ? element.LocationId : null,
                    LandedCost: element.LandedCost,
                });
            }
            return false;
        });
        let billingCharges = ObjectWms.removeObjFromListOnParameterValue(
            adjustInventoryData.Billing,
            "NumberOfUnits",
            null
        );
        billingCharges.map((element) => {
            if (!element.UnitDescription || element.UnitDescription == "") {
                element.UnitDescription = "Each";
            }
            formData.Billing.push(element);
            return false;
        });

        kendo.ui.progress($("#ReverseAdjustmentModal"), true);
        props.onApplyCreateAdjustment(formData);
    };

    const validateTrackByall = () => {
        return (
            (requireLotNumberTrackby() && !currentAdjustmentItem.LotNumber) ||
            (requireSerialNumberTrackby() && !currentAdjustmentItem.SerialNumber) ||
            (requireExpirationDateTrackby() &&
                (currentAdjustmentItem.ExpirationDate == null || currentAdjustmentItem.ExpirationDate === "")) ||
            (requireCostTrackby() && !currentAdjustmentItem.LandedCost) ||
            (requireLocationIdTrackBy() &&
                (!currentAdjustmentItem.LocationId || currentAdjustmentItem.LocationId.toString() === "0")) ||
            (requireMovableUnitLabelTrackBy() && currentAdjustmentItem.MovableUnitLabel === "")
        );
    };

    const requireLotNumberTrackby = () => {
        return currentAdjustmentItem.type === "add" && itemTrackByDetail.TrackLotNumber === 2;
    };
    const requireSerialNumberTrackby = () => {
        return (
            currentAdjustmentItem.type === "add" &&
            (itemTrackByDetail.TrackSerialNumber === 3 || itemTrackByDetail.TrackSerialNumber === 4)
        );
    };
    const requireExpirationDateTrackby = () => {
        return currentAdjustmentItem.type === "add" && itemTrackByDetail.TrackExpirationDate === 2;
    };
    const requireCostTrackby = () => {
        return currentAdjustmentItem.type === "add" && itemTrackByDetail.TrackCost === 2;
    };
    const requireLocationIdTrackBy = () => {
        return currentAdjustmentItem.type === "add" && trackLocation === 2;
    };
    const requireMovableUnitLabelTrackBy = () => {
        return currentAdjustmentItem.type === "add" && muTrackPallats == 2;
    };
    const openCreateManualChargeModel = () => {
        AdjustInventoryServices.getCharges().then((response) => {
            response.data.unshift({
                "id": null,
                "Description": window.translate("Label_Select_Charge_Type"),
            });
            setChargesType(response.data);
            setIsOpenManualChargeModal(true);
        });
    };
    const onCloseCreateManualChargeModal = () => {
        setIsOpenManualChargeModal(false);
    };
    const addOrderChargesGrid = (data) => {
        var ManualCharge = {
            "Action": "a",
            "ChargeOrigin": 2,
            "ChargeLabel": data.ChargeLabel,
            "ChargeTypeId": data.ChargeTypeId,
            "ChargeTypeDescription": data.ChargeType,
            "UnitDescription": data.Description,
            "ChargePerUnit": data.ChargePerUnit,
            "NumberOfUnits": data.NumberOfUnits,
            "TotalCharges": data.TotalCharges,
            "GeneralLedgerAccount": data.GeneralLedgerAccount,
            "ExternalAccountsReceivable": data.ExternalAccountsReceivable,
            "ExternalItemIdentifier": data.ExternalItemIdentifier,
            "ExternalItemDescription": data.ExternalItemDescription,
            "SKU": "",
            "Notes": "",
            "UnsavedManualCharge": true,
            "SystemGenerated": false,
        };
        setAdjustInventoryData((prevState: any) => ({
            ...prevState,
            Billing: ObjectWms.removeObjFromListOnParameterValue(
                [...prevState.Billing, ManualCharge],
                "SystemGenerated",
                true
            ),
        }));
    };
    const openConfirmationModal = () => {
        setIsOpenConfirmModel(true);
    };
    const clickExit = () => {
        setIsOpenConfirmModel(false);
    };
    const deleteManualCharges = () => {
        var grid = $("#" + props.wmsSelector + "chargeGrid").data("kendoGrid");
        grid.select().each(function () {
            grid.removeRow($(this));
        });
        var gridData = grid.dataSource.data().length > 0 ? grid.dataSource.data().toJSON() : [];

        setAdjustInventoryData((previousState) => ({
            ...previousState,
            Billing: gridData,
        }));
        setIsManualCharge(false);
        grid.clearSelection();
        setIsOpenConfirmModel(false);
        setIsOpenManualChargeModal(false);
    };
    const chargeSelectRow = (param_kendogrid) => {
        var selectedRow = param_kendogrid.getNumberOfSelections();
        var manualChargeType =
            selectedRow > 0 ? param_kendogrid.identicalValuesInSelectedSet("ChargeOrigin", 2) : false;

        setIsManualCharge(manualChargeType);
    };
    const onDataBound = (e, kendoGrid) => {
        if (kendoGrid) {
            var totalCharges = kendoGrid.sumByLocalMemoryGridContents(["TotalCharges"])[0];
            let tmpTotalSum = Number(Math.round(parseFloat(totalCharges + "e2")) + "e-2");
            let formatTotalSum = tmpTotalSum
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","); // eslint-disable-line security/detect-unsafe-regex
            setTotalSum(formatTotalSum);
        }
    };
    const onEdit = (e) => {
        var grid = $("#" + props.wmsSelector + "chargeGrid").data("kendoGrid");
        var columnIndex = grid.cellIndex(e.container);
        var fieldName = grid.thead.find("th").eq(columnIndex).data("field");

        if (fieldName === "NumberOfUnits" && e.model.ChargeOrigin === 1) {
            grid.closeCell();
        }
        if (fieldName === "ChargePerUnit" && e.model.ChargeOrigin === 1) {
            grid.closeCell();
        }
        if (fieldName === "ChargePerUnit" && e.model.ChargeOrigin === 3) {
            grid.closeCell();
        }
    };
    const EventSaveGridAppliedCharges = (e) => {
        var grid = $(" #" + props.wmsSelector + "chargeGrid").data("kendoGrid");

        var numberOfUnits = StringWms.isEmpty(e.model.NumberOfUnits) ? 0 : e.model.NumberOfUnits;
        if (e.values.hasOwnProperty("NumberOfUnits")) {
            numberOfUnits = StringWms.isEmpty(e.values.NumberOfUnits) ? 0 : e.values.NumberOfUnits;
        }

        var chargePerUnit = StringWms.isEmpty(e.model.ChargePerUnit) ? 0 : e.model.ChargePerUnit;
        if (e.values.hasOwnProperty("ChargePerUnit")) {
            chargePerUnit = StringWms.isEmpty(e.values.ChargePerUnit) ? 0 : e.values.ChargePerUnit;
        }

        var total = parseFloat(new Big(numberOfUnits).times(chargePerUnit).round(4));

        e.model.set("TotalCharges", total);
        e.model.TotalCharges = total;
        grid.saveChanges();

        // Setting a timeout so that NumberOfUnits gets updated by gridSaveChanges
        setTimeout(() => {
            let billingChargesData = grid._data;
            billingChargesData = ObjectWms.removeObjFromListOnParameterValue(billingChargesData, "test", null);

            setAdjustInventoryData((prevState: any) => ({
                ...prevState,
                Billing: billingChargesData,
            }));
        }, 100);
    };
    const appliedChargesGridChange = (e) => {
        //   Still nothing to do here.
    };
    const calculateChargesClick = () => {
        setIsManualChargeLoading(true);
        //This need to be review as flow dont have  Adjustment Id on create adjust inventory.
        calculateCharges(
            loadAutoGeneratedCharges,
            adjustInventoryData.Billing.filter((el) => el.ChargeOrigin !== 1)
        );
    };

    const calculateCharges = (callback, manualCharges) => {
        AdjustInventoryServices.InventoryCalculateCharges(adjustInventoryData, callback == loadAutoGeneratedCharges)
            .then((response) => {
                if (response.data) {
                    response.data = response.data.map((item, index) => {
                        item.rowId = index;
                        return item;
                    });
                    let charges = response.data;
                    if (callback) {
                        charges = callback(charges);
                    }
                    setAdjustInventoryData((prevState: any) => ({
                        ...prevState,
                        Billing: [...charges, ...manualCharges],
                    }));
                }
            })
            .finally(() => {
                setIsManualChargeLoading(false);
            });
    };

    const loadPredefinedCharges = (billing) => {
        return ObjectWms.removeObjFromListOnParameterValue(billing ?? [], "SystemGenerated", true);
    };

    const loadAutoGeneratedCharges = (billing) => {
        GlobalService.notification("success", window.translate("Label_Success_recalculateCharges"));
        return ObjectWms.removeObjFromListOnParameterValue(billing ?? [], "SystemGenerated", false);
    };

    return (
        <>
            <WmsModel
                id="CreateNewInventoryAdjustmentModel"
                title={
                    isIncludeChargeShow
                        ? window.translate("Manual_charges")
                        : window.translate("Create_New_Inventory_adjustment")
                }
                width={800}
                height={800}
                onCloseFunc={props.onCloseCreateNewInventoryAdjustmentModal}
                isOpen={props.isOpenCreateNewInventoryAdjustmentModal}
                customClass="wms-model-new-ui-wrapper"
            >
                <div className={isIncludeChargeShow ? "model-content-wrapper" : "model-content-wrapper hide"}>
                    <WmsCollapse
                        id={"InventoryAdjustmentManualChargesModel"}
                        headerLabel={window.translate("adjustment_information")}
                        showCollapseText={false}
                    >
                        <div className="adjustment-information-wrapper">
                            <div className="display-inline">
                                <WmsInput
                                    id="Notes"
                                    inputName="Notes"
                                    wmsSelector={props.wmsSelector + "Notes"}
                                    onChangeFunc={handleChangeInventoryData}
                                    label={window.translate("Transaction_Notes")}
                                    value={adjustInventoryData.Notes}
                                    extraWrapperClass="charge-notes width-70"
                                />
                                <WmsInput
                                    id="PONumber"
                                    inputName="PONumber"
                                    wmsSelector={props.wmsSelector + "PONumber"}
                                    onChangeFunc={handleChangeInventoryData}
                                    label={window.translate("PO_Number")}
                                    value={adjustInventoryData.PONumber}
                                    extraWrapperClass="width-40"
                                />
                            </div>
                            <hr />
                            <div className="display-inline charge-grid-wrapper">
                                <WmsGrid
                                    id={props.wmsSelector + "chargeGrid"}
                                    wmsSelector={props.wmsSelector + "chargeGrid"}
                                    gridKeySelection={"rowId"}
                                    gridName={props.wmsSelector + "chargeGrid"}
                                    isAlignIndexesByProperty={true}
                                    virtual={false}
                                    columns={chargesGridColumns}
                                    schema={chargesGridSchema}
                                    showGridSummary={false}
                                    onSelection={chargeSelectRow}
                                    clickEventOfContextMenu={() => null}
                                    shawCheckbox={false}
                                    showGridResult={false}
                                    staticGridData={adjustInventoryData.Billing}
                                    showSortColumns={false}
                                    onDataBound={onDataBound}
                                    gridEditable={{
                                        confirmation: false,
                                    }}
                                    onEdit={onEdit}
                                    onGridSave={EventSaveGridAppliedCharges}
                                    onChange={appliedChargesGridChange}
                                    menu={[
                                        {
                                            "name": window.translate("Label_Recalculate_AutoCharges"),
                                            "value": [],
                                            "disabled": isManualChargeLoading,
                                            "disabledText": "You may not add blahssss",
                                            "icon": "fa fa-calculator",
                                            "color": "green",
                                            "clickFunc": calculateChargesClick,
                                            "wmsSelector": props.wmsSelector + "RecalculateAutoCharges",
                                            "loading": isManualChargeLoading,
                                            "isContextMenu": false,
                                            "isButtonShaw": rights.hasOwnProperty(
                                                "Integrations.isNotActiveBillingManagerApp"
                                            ),
                                        },
                                        {
                                            "name": window.translate("Label_Create_Manual_Charge"),
                                            "value": [],
                                            "disabled": false,
                                            "icon": "fa fa-keyboard-o",
                                            "color": "green",
                                            "clickFunc": openCreateManualChargeModel,
                                            "wmsSelector": props.wmsSelector + "CreateManualCharge",
                                            "isContextMenu": false,
                                        },
                                        {
                                            "name": window.translate("Label_Delete"),
                                            "value": [],
                                            "disabledText": "You may not add blahssss",
                                            "disabled": !isManualCharge,
                                            "icon": "fa-trash",
                                            "color": "red",
                                            "clickFunc": openConfirmationModal,
                                            "wmsSelector": props.wmsSelector + "DeleteManualCharge",
                                            "isContextMenu": false,
                                        },
                                    ]}
                                />
                                <div className="charge-totalWrapper">
                                    <div className="total-text">TOTAL</div>
                                    <div className="total-input">
                                        <WmsInput
                                            id="totalInput"
                                            inputName="totalInput"
                                            wmsSelector={props.wmsSelector + "totalInput"}
                                            onChangeFunc={() => {}}
                                            label={""}
                                            value={totalSum}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WmsCollapse>
                </div>

                <div className={isIncludeChargeShow ? "model-content-wrapper hide" : "model-content-wrapper"}>
                    <WmsCollapse
                        id={"InventoryAdjustmentCreateAdjustmentModel"}
                        headerLabel={window.translate("adjustment_information")}
                        showCollapseText={false}
                    >
                        <div className="adjustment-information-wrapper">
                            <div className="display-inline">
                                <WmsDropdown
                                    id="customer"
                                    name="CustomerId"
                                    label={CustomLabels.Label_Customer}
                                    wmsSelector={props.wmsSelector + "SelectCustomer"}
                                    value={adjustInventoryData.CustomerIdentifier.Id}
                                    onChangeFunc={handleChangeInventoryData}
                                    options={CustomerOptions}
                                    valueField={"id"}
                                    textField={"name"}
                                    required={true}
                                />
                                <WmsDropdown
                                    id="warehouseFacilityID"
                                    name="FacilityID"
                                    label={CustomLabels.Label_Warehouse}
                                    wmsSelector={props.wmsSelector + "SelectWarehouse"}
                                    value={adjustInventoryData.FacilityIdentifier.Id}
                                    onChangeFunc={handleChangeInventoryData}
                                    options={facilityOPtions}
                                    valueField={"id"}
                                    textField={"name"}
                                    required={true}
                                />
                            </div>
                            <div className="display-inline">
                                <WmsInput
                                    id="ReferenceNum"
                                    inputName="ReferenceNum"
                                    wmsSelector={props.wmsSelector + "ReferenceNum"}
                                    onChangeFunc={handleChangeInventoryData}
                                    label={window.translate("Orders_Lbl_Reference_Num")}
                                    value={adjustInventoryData.ReferenceNum}
                                    required={true}
                                    extraWrapperClass="width-40"
                                />
                                <WmsInput
                                    id="PONumber"
                                    inputName="PONumber"
                                    wmsSelector={props.wmsSelector + "PONumber"}
                                    onChangeFunc={handleChangeInventoryData}
                                    label={window.translate("PO_Number")}
                                    value={adjustInventoryData.PONumber}
                                    extraWrapperClass="width-40"
                                />
                            </div>
                            <div className="display-inline">
                                <WmsTextArea
                                    id="Notes"
                                    name="Notes"
                                    wmsSelector={props.wmsSelector + "Notes"}
                                    onChangeFunc={handleChangeInventoryData}
                                    label={window.translate("Label_SectionTitle_Notes")}
                                    value={adjustInventoryData.Notes}
                                    extraWrapperClass="charge-notes width-70"
                                    maxLength={4096}
                                    rows={3}
                                    cols={50}
                                />
                            </div>
                            <div className="adjustment-information-wrapper">
                                <div className="display-inline">
                                    <WmsCheckBox
                                        id={"IncludeCharges"}
                                        name={"IncludeCharges"}
                                        wmsSelector={props.wmsSelector + "IncludeCharges"}
                                        onChangeFunc={handleChangeInventoryData}
                                        label={window.translate("Include_Charges")}
                                        checked={adjustInventoryData.IncludeCharges}
                                        parentClassName="include-charge-wrapper"
                                    />
                                    {rights.hasOwnProperty("Integrations.isNotActiveBillingManagerApp") && (
                                        <WmsCheckBox
                                            id={"CreateInvoice"}
                                            name={"CreateInvoice"}
                                            wmsSelector={props.wmsSelector + "CreateInvoice"}
                                            onChangeFunc={handleChangeInventoryData}
                                            label={window.translate("Create_Invoice_Adjust_inventory")}
                                            checked={adjustInventoryData.CreateInvoice}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="adjustment-information-wrapper ">
                            <div className="display-inline">
                                <WmsRadio
                                    id="adjustment-item-Add-inventory"
                                    name="adjustmentType"
                                    label={window.translate("add_inventory")}
                                    wmsSelector={props.wmsSelector + "adjustment-item-Add-inventory"}
                                    onChangeFunc={(e) => handleAdjustmentType("add")}
                                    checked={currentAdjustmentItem.type === "add"}
                                    disabled={isCompletePrimaryData()}
                                />
                                <WmsRadio
                                    id="adjustment-item-Reduce-inventory"
                                    name="adjustmentType"
                                    label={window.translate("reduce_inventory")}
                                    wmsSelector={props.wmsSelector + "adjustment-item-Reduce-inventory"}
                                    onChangeFunc={(e) => handleAdjustmentType("reduce")}
                                    checked={currentAdjustmentItem.type === "reduce"}
                                    disabled={isCompletePrimaryData()}
                                />
                            </div>
                        </div>
                        <div className="adjustment-item-wrapper">
                            <div className="error-message">
                                {showMessageNoReceiveItemAsFilter ? (
                                    <span>
                                        No receiveitems match the specified filter set. Try again with fewer filters
                                        selected.
                                    </span>
                                ) : null}
                            </div>
                            <div className="display-inline">
                                <WmsDropdownList
                                    id="SkuDropDownList"
                                    name="SkuDropDownList"
                                    label={CustomLabels.Label_Sku}
                                    optionsLabel={window.translate("Label_Select_Sku")}
                                    wmsSelector={props.wmsSelector + "adjustment-item-SkuDropDown"}
                                    onSelect={changecurrentAdjustmentItemSku}
                                    value={currentAdjustmentItem.ItemIdentifierId}
                                    onChangeFunc={() => {}}
                                    disabled={isCompletePrimaryData()}
                                    textField="Sku"
                                    valueField="Id"
                                    showDetailsTooltip={true}
                                    windowId={props.windowId}
                                    parentclassName="form-group"
                                    template="<span style='white-space: nowrap;text-overflow: ellipsis;width: 210px;display: block;overflow: hidden'>#if (Description == null) {# #: Sku# #}else{# #:Sku# - #:Description#  #}#</span>"
                                    extraWrapperClass="width-40 sku-dropdown-wrapper"
                                    required={true}
                                    virtualObj={{
                                        itemHeight: 45,
                                        valueMapper: (options) => {
                                            var valueMapperOptions = options;
                                            $.ajax({
                                                url: "/WebUI/items/items/SkuDropdownIndexRead",
                                                type: "POST",
                                                dataType: "json",
                                                data: {
                                                    itemId: valueMapperOptions.value || 0,
                                                    customerId: selectedCustomerId || 0,
                                                },
                                                success: function (data) {
                                                    options.success(data);
                                                },
                                            });
                                        },
                                    }}
                                    dataSourceObj={{
                                        type: "aspnetmvc-ajax",
                                        pageSize: 30,
                                        serverPaging: true,
                                        serverFiltering: true,
                                        transport: {
                                            read: {
                                                url: "/WebUI/items/items/SkuDropDownRead",
                                                type: "POST",
                                                data: {
                                                    customerId: adjustInventoryData.CustomerIdentifier.Id,
                                                },
                                            },
                                        },
                                        schema: {
                                            data: "Data",
                                            total: "Total",
                                        },
                                    }}
                                />
                                {qualifiersOptions.length ? (
                                    <WmsDropdown
                                        id="SelectQualifier"
                                        name="Qualifier"
                                        label={window.translate("Label_Qualifier")}
                                        wmsSelector={props.wmsSelector + "SelectQualifier"}
                                        value={currentAdjustmentItem.Qualifier}
                                        onChangeFunc={changecurrentAdjustmentItem}
                                        options={qualifiersOptions}
                                        valueField={"Qualifier"}
                                        disabled={isCompletePrimaryData()}
                                        textField={"Qualifier"}
                                        extraWrapperClass={"width-40"}
                                    />
                                ) : null}
                                <WmsInput
                                    id="Qty"
                                    inputName="Qty"
                                    wmsSelector={props.wmsSelector + "Qty"}
                                    onChangeFunc={changecurrentAdjustmentItem}
                                    label={window.translate("Label_Quantity")}
                                    value={currentAdjustmentItem.Qty}
                                    disabled={isCompletePrimaryData()}
                                    extraWrapperClass="width-20"
                                    required={true}
                                    regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                                />
                            </div>
                            {currentAdjustmentItem.type === "reduce" && (
                                <div className="display-inline">
                                    <WmsCheckBox
                                        id={"AutoSelectInventory"}
                                        name={"AutoSelectInventory"}
                                        wmsSelector={props.wmsSelector + "AutoSelectInventory"}
                                        onChangeFunc={handleChangeInventoryData}
                                        disabled={isCompletePrimaryData()}
                                        label={window.translate("AutoSelectInventory")}
                                        checked={adjustInventoryData.AutoSelectInventory}
                                    />
                                </div>
                            )}
                            <div className="display-inline">
                                {itemTrackByDetail.TrackLotNumber !== 0 && (
                                    <WmsInput
                                        id="LotNumber"
                                        inputName="LotNumber"
                                        wmsSelector={props.wmsSelector + "LotNumber"}
                                        onChangeFunc={changecurrentAdjustmentItem}
                                        label={window.translate("Label_Lot_Number")}
                                        disabled={isCompletePrimaryData() || adjustInventoryData.AutoSelectInventory}
                                        value={currentAdjustmentItem.LotNumber}
                                        required={requireLotNumberTrackby()}
                                        extraWrapperClass="width-25"
                                    />
                                )}
                                {itemTrackByDetail.TrackSerialNumber !== 0 && (
                                    <WmsInput
                                        id="SerialNumber"
                                        inputName="SerialNumber"
                                        wmsSelector={props.wmsSelector + "SerialNumber"}
                                        onChangeFunc={changecurrentAdjustmentItem}
                                        label={window.translate("Label_Serial_Number")}
                                        disabled={isCompletePrimaryData() || adjustInventoryData.AutoSelectInventory}
                                        value={currentAdjustmentItem.SerialNumber}
                                        required={requireSerialNumberTrackby()}
                                        extraWrapperClass="width-25"
                                    />
                                )}

                                {itemTrackByDetail.TrackExpirationDate !== 0 && (
                                    <WmsDateTimePicker
                                        id={"ExpirationDate"}
                                        name="ExpirationDate"
                                        label={window.translate("Label_Expiration_Date")}
                                        onChangeFunc={changecurrentAdjustmentItem}
                                        value={currentAdjustmentItem.ExpirationDate}
                                        disabled={isCompletePrimaryData() || adjustInventoryData.AutoSelectInventory}
                                        required={requireExpirationDateTrackby()}
                                        wmsSelector={props.wmsSelector + "EarliestShipDate"}
                                        format={"M/d/yyyy"}
                                        isDateParseInCustomFormat={true}
                                    />
                                )}
                                {itemTrackByDetail.TrackCost !== 0 && (
                                    <WmsInput
                                        id="LandedCost"
                                        inputName="LandedCost"
                                        wmsSelector={props.wmsSelector + "LandedCost"}
                                        onChangeFunc={changecurrentAdjustmentItem}
                                        label={window.translate("Label_MassAdd_Cost")}
                                        disabled={isCompletePrimaryData() || adjustInventoryData.AutoSelectInventory}
                                        value={currentAdjustmentItem.LandedCost}
                                        required={requireCostTrackby()}
                                        extraWrapperClass="width-20"
                                    />
                                )}
                            </div>
                        </div>
                        <div className="adjustment-item-wrapper">
                            <div className="display-inline">
                                <WmsDropdownList
                                    id="LocationId"
                                    name="LocationId"
                                    label={window.translate("Label_Location")}
                                    wmsSelector={props.wmsSelector + "LocationId"}
                                    value={currentAdjustmentItem.LocationId}
                                    onChangeFunc={changecurrentAdjustmentItem}
                                    textField="Name"
                                    valueField="Id"
                                    disabled={isCompletePrimaryData() || adjustInventoryData.AutoSelectInventory}
                                    extraWrapperClass="location-dropdown-list width-30"
                                    required={requireLocationIdTrackBy()}
                                    // template={"<span style='white-space: nowrap;text-overflow: ellipsis;width: 210px;display: block;overflow: hidden'>${ Name } - ${Description}</span>"}
                                    dataSourceObj={{
                                        type: "aspnetmvc-ajax",
                                        pageSize: 50,
                                        serverPaging: true,
                                        serverFiltering: true,
                                        transport: {
                                            read: {
                                                url: "/WebUI/items/items/LocationDropDownRead",
                                                type: "POST",
                                                data: {
                                                    facilityId: adjustInventoryData.FacilityIdentifier.Id,
                                                },
                                            },
                                        },
                                        schema: {
                                            data: "Data",
                                            total: "Total",
                                        },
                                    }}
                                />
                                {muTrackPallats !== 0 && currentAdjustmentItem.type === "reduce" && (
                                    <WmsInput
                                        id="MovableUnitLabel"
                                        inputName="MovableUnitLabel"
                                        wmsSelector={props.wmsSelector + "MovableUnitLabel"}
                                        onChangeFunc={changecurrentAdjustmentItem}
                                        label={CustomLabels.Label_Movable_Unit}
                                        disabled={isCompletePrimaryData() || adjustInventoryData.AutoSelectInventory}
                                        value={currentAdjustmentItem.MovableUnitLabel}
                                        extraWrapperClass="width-25"
                                    />
                                )}
                                {muTrackPallats !== 0 && currentAdjustmentItem.type === "add" && (
                                    <WmsComboBox
                                        key={
                                            "MovableUnitLabel_" +
                                            (isCompletePrimaryData() || adjustInventoryData.AutoSelectInventory)
                                        }
                                        id="MovableUnitLabel"
                                        name="MovableUnitLabel"
                                        label={CustomLabels.Label_Movable_Unit}
                                        wmsSelector={props.wmsSelector + "MovableUnitList"}
                                        value={currentAdjustmentItem.MovableUnitLabel}
                                        onChangeFunc={changecurrentAdjustmentItem}
                                        textField="Name"
                                        valueField="Value"
                                        disabled={isCompletePrimaryData() || adjustInventoryData.AutoSelectInventory}
                                        placeholder={"Select MU Label"}
                                        options={optionsMovableUnitLabels}
                                        required={requireMovableUnitLabelTrackBy()}
                                        parentClassName={"width-25"}
                                    />
                                )}
                                {currentAdjustmentItem.type !== "reduce" ? (
                                    <WmsCheckBox
                                        id={"SplitLineByMovableUnit"}
                                        name={"SplitLineByMovableUnit"}
                                        wmsSelector={props.wmsSelector + "SplitLineByMovableUnit"}
                                        onChangeFunc={changecurrentAdjustmentItem}
                                        disabled={isCompletePrimaryData() || adjustInventoryData.AutoSelectInventory}
                                        label={window.translate("Label_Split_Movable_Unit")}
                                        checked={currentAdjustmentItem.SplitLineByMovableUnit}
                                        parentClassName="split-line-by-movable-unit"
                                    />
                                ) : null}
                            </div>
                        </div>
                        <div className="adjustment-add-button-area">
                            <div className="display-inline">
                                <WmsButton
                                    label={window.translate("Label_Add")}
                                    wmsSelector={props.wmsSelector + "CreateAdjustInventoryItem"}
                                    onClickFunc={createAdjustInventoryItem}
                                    icon={"fa-plus"}
                                    disabled={
                                        currentAdjustmentItem.ItemIdentifierId === 0 ||
                                        !currentAdjustmentItem.Qty ||
                                        currentAdjustmentItem.Qty <= 0 ||
                                        (currentAdjustmentItem.type === "add" && validateTrackByall())
                                    }
                                    loading={loadingReceiveItems}
                                />
                            </div>
                        </div>
                        <div className="adjustment-add-button-area">
                            <div className="display-inline adjust-inventory-item-grid-wrapper">
                                <WmsFormGrid
                                    id={"AdjustInventoryItemGrid"}
                                    wmsSelector={"AdjustInventoryItem"}
                                    gridKeySelection={""}
                                    columns={adjustmentItemsGridColumns}
                                    staticGridData={adjustInventoryData.AdjustmentItems}
                                    schema={adjustmentItemsGridSchema}
                                    isColumnsResizable={true}
                                    isColumnsReorderable={true}
                                    isColumnMenuenable={false}
                                    isColumnsSortable={true}
                                    parentClassName={"adjust-inventory-item-grid"}
                                    showResetMenu={false}
                                    onSelection={() => {}}
                                    IsMultipleSelectable={true}
                                    onDataBound={onBoundAdjustInventoryItemGrid}
                                />
                            </div>
                        </div>
                    </WmsCollapse>
                </div>
                <div className="footer-btn-holder">
                    {isIncludeChargeShow ? (
                        <WmsButton
                            label={window.translate("RoleManager_Label_Back")}
                            wmsSelector={props.wmsSelector + "SelectReceiveItemModelDiscardExit"}
                            onClickFunc={() => {
                                nextIncludeCharge(false);
                                setAdjustInventoryData((prevState: any) => ({
                                    ...prevState,
                                    Billing: [],
                                }));
                            }}
                            buttonType="orange"
                            icon={"fa-angle-left"}
                        />
                    ) : (
                        <WmsButton
                            label={window.translate("Label_Cancel")}
                            wmsSelector={props.wmsSelector + "DiscardExit"}
                            onClickFunc={props.onCloseCreateNewInventoryAdjustmentModal}
                            buttonType="orange"
                            icon={"fa-ban"}
                        />
                    )}
                    {adjustInventoryData.IncludeCharges && !isIncludeChargeShow && (
                        <WmsButton
                            label={window.translate("Label_Next")}
                            wmsSelector={props.wmsSelector + "nextIncludeCharge"}
                            onClickFunc={() => {
                                nextIncludeCharge(true);
                            }}
                            icon={"fa-caret-right"}
                            disabled={allValidationDone()}
                            loading={props.applyingAdjustment}
                        />
                    )}
                    {(!adjustInventoryData.IncludeCharges || isIncludeChargeShow) && (
                        <WmsButton
                            label={window.translate("Apply_Adjustment")}
                            wmsSelector={props.wmsSelector + "SaveExit"}
                            onClickFunc={createAdjustment}
                            icon={"fa-floppy-o"}
                            disabled={allValidationDone()}
                            loading={props.applyingAdjustment}
                            parentClassName={"apply-adjustment-button"}
                        />
                    )}
                </div>
            </WmsModel>
            {isOpenSelectReceiveItemModel && (
                <SelectReceiveItemModel
                    isOpenSelectReceiveItemModel={isOpenSelectReceiveItemModel}
                    onCloseSelectReceiveItemModel={onCloseSelectReceiveItemModel}
                    currentAdjustmentItem={currentAdjustmentItem}
                    availableReceiveItemData={availableReceiveItemData}
                    windowId={props.windowId}
                    wmsSelector={props.wmsSelector}
                    applyingAdjustment={props.applyingAdjustment}
                    itemIsIntegral={itemIsIntegral}
                    addReduceInventoryAdjustmentItems={addReduceInventoryAdjustmentItems}
                />
            )}
            {isOpenManualChargeModal && (
                <CreateManualChargeModel
                    sharedIsOpenManualChargeModal={isOpenManualChargeModal}
                    sharedManualChargeOptions={chargesType}
                    onCloseAddManualChargeModel={onCloseCreateManualChargeModal}
                    onAddShipAndCloseGridData={addOrderChargesGrid}
                    isAnotherModelOpen={false}
                    windowId={props.windowId}
                />
            )}
            {isOpenConfirmModel && (
                <WmsModel
                    id={props.windowId + "confirmDeleteManualCharge"}
                    title={window.translate("Label_Confirm_Delete_Manual_Charge")}
                    width={500}
                    height={175}
                    class="confirmDeleteManualCharge"
                    onCloseFunc={clickExit}
                    customClass="wms-model-new-ui-wrapper"
                    isOpen={isOpenConfirmModel}
                >
                    <div className="model-content-wrapper">{window.translate("Label_Confirm_Selected_Charges")}</div>
                    <div className="footer-btn-holder">
                        <WmsButton
                            label={window.translate("Label_Cancel")}
                            wmsSelector={props.wmsSelector + "Cancel"}
                            onClickFunc={clickExit}
                            disabled={false}
                            loading={false}
                            buttonType="yellow"
                            icon={"fa-ban"}
                        />
                        <WmsButton
                            label={window.translate("Label_Confirm_Delete")}
                            wmsSelector={props.wmsSelector + "ConfirmDelete"}
                            onClickFunc={deleteManualCharges}
                            disabled={false}
                            loading={false}
                            buttonType="red"
                            icon={"fa-trash"}
                        />
                    </div>
                </WmsModel>
            )}
        </>
    );
};
CreateNewInventoryAdjustmentModal.propTypes = {};

CreateNewInventoryAdjustmentModal.defaultProps = {};

export default React.memo(CreateNewInventoryAdjustmentModal);
