import React from 'react';

import WmsButton from '../../../components/WmsButton/WmsButton.jsx';
import WmsDropdown from '../../../components/WmsDropdown/WmsDropdown.jsx';
import WmsModel from '../../../components/WmsModel/WmsModel.jsx';
import WmsInput from '../../../components/WmsInput/WmsInput.jsx';
import WmsCollapse from '../../../components/WmsCollapse/WmsCollapse.jsx';
import style from './carrier-markup.scss';
import * as GlobalService from '../../../global_services';
import * as carrierService from '../../small-parcel-settings.services.jsx';

export class CarrierMarkupModel extends React.Component {

    constructor(props) {

        super(props);


        this.translate = window.translate;
        this.state = {
            carrierMarkUpsList: [],
            servicesOptions: [],
            deleteUris: [],
            saveLoader: false,
            disableSaveText: this.translate("Label_Markup_Save_Disable_Text"),
            isSaveDisabled: true
        };
    }

    componentDidMount() {
        kendo.ui.progress($('#carrierMarkUp'), true);
        carrierService.getCarrierMarkup(this.props.sharedSelectedCustomer.id).then((response) => {

            var tmpCarrierMarkUpResponse = response.data;
            var sharedCarrierAccountsGridData = this.props.sharedCarrierAccountsGridData;
            var markupServicesArr = {};
            var carrierActualNameObj = {};

            // create carrier object array from carrier grid data
            for (let i = 0; i < sharedCarrierAccountsGridData.length; i++) {
                const element = sharedCarrierAccountsGridData[i];
                var carrierInLowerCase = element.Carrier.toLowerCase();
                markupServicesArr[carrierInLowerCase] = [];
                carrierActualNameObj[carrierInLowerCase] = element.Carrier;
            }

            // add carrier markup flat rate from markup response
            for (let i = 0; i < tmpCarrierMarkUpResponse.length; i++) {
                const element = tmpCarrierMarkUpResponse[i];
                var carrierInLowerCase = element.Carrier.toLowerCase();
                if (markupServicesArr[carrierInLowerCase]) {
                    markupServicesArr[carrierInLowerCase].push(element)
                }
            }

            // check  null shipmentService is available if not then create and at last make first index
            Object.keys(markupServicesArr).map((key, index) => {
                var isNullServiceAvailable = false;

                for (let i = 0; i < markupServicesArr[key].length; i++) {
                    const element = markupServicesArr[key][i];
                    if (element.ShipmentService == null) {
                        isNullServiceAvailable = true;
                    }
                }

                if (isNullServiceAvailable == false) {
                    markupServicesArr[key].push({
                        Carrier: carrierActualNameObj[key],
                        CarrierMarkupUri: null,
                        CustomerId: this.props.sharedSelectedCustomer.id,
                        CustomerName: this.props.sharedSelectedCustomer.name,
                        CustomerUri: this.props.sharedSelectedCustomer.uri,
                        FlatRateMarkup: 0,
                        PercentMarkup: 0,
                        ShipmentService: null,
                    })
                }
                markupServicesArr[key].sort(function (x, y) { return x.ShipmentService == null ? -1 : y.ShipmentService == null ? 1 : 0; });
            });

            this.setState({
                isSaveDisabled: this.validateCarrierMarkups(markupServicesArr),
                carrierMarkUpsList: markupServicesArr
            });
            this.disableServiceOptions(response.data);

        }).finally(() => {
            kendo.ui.progress($('#carrierMarkUp'), false);
        });
    }

    handleChangeEvent = (key, param_index) => (e) => {
        let serviceOption = this.state.carrierMarkUpsList[key];

        if (e.target.name === "PercentMarkup" || e.target.name === "FlatRateMarkup") {
            serviceOption[param_index][e.target.name] = e.target.value ? e.target.value : 0;
        } else {
            serviceOption[param_index][e.target.name] = e.target.value;
        }

        this.disableServiceOptions(serviceOption);

        var tmpCarrierMarkupList = Object.assign(this.state.carrierMarkUpsList, { [key]: serviceOption });

        this.setState({
            isSaveDisabled: this.validateCarrierMarkups(tmpCarrierMarkupList),
            carrierMarkUpsList: tmpCarrierMarkupList
        });
    }

    validateCarrierMarkups = (carrierMarkUp) => {

        var isSaveDisabled = false;
        for (const key in carrierMarkUp) {
            for (let i = 1; i < carrierMarkUp[key].length; i++) {
                const element = carrierMarkUp[key][i];
                if (!element.ShipmentService || !(element.PercentMarkup || element.FlatRateMarkup)) {
                    isSaveDisabled = true;
                    break;
                }
            }
            if (isSaveDisabled) {
                break;
            }
        }

        return isSaveDisabled;
    }

    manageCarrierMarkUp = (key, param_index) => (e) => {
        let serviceOption = this.state.carrierMarkUpsList[key];
        let deleteUris = this.state.deleteUris;

        if (typeof param_index != 'undefined') {
            var removeCarrierUri = serviceOption[param_index].CarrierMarkupUri;
            if (removeCarrierUri) {
                deleteUris.push(removeCarrierUri);
            }

            serviceOption.splice(param_index, 1);
        } else {
            serviceOption.push({
                Carrier: key,
                CarrierMarkupUri: null,
                CustomerId: this.props.sharedSelectedCustomer.id,
                CustomerName: this.props.sharedSelectedCustomer.name,
                CustomerUri: this.props.sharedSelectedCustomer.uri,
                FlatRateMarkup: 0,
                PercentMarkup: 0,
                ShipmentService: null,
            });

        }
        this.disableServiceOptions(serviceOption);
        var tmpCarrierMarkupList = Object.assign(
            this.state.carrierMarkUpsList,
            {
                [key]: serviceOption
            }
        );
        this.setState({
            isSaveDisabled: this.validateCarrierMarkups(tmpCarrierMarkupList),
            carrierMarkUpsList: tmpCarrierMarkupList,
            deleteUris: deleteUris
        });
    }

    disableServiceOptions = (carrierMarkUp) => {
        setTimeout(() => {
            carrierMarkUp.forEach((service, index) => {
                if (index == 0) {
                    $("#" + service.Carrier.replace(/\s+/g, '') + "_ShipmentService option").removeAttr('disabled');
                }
                $("#" + service.Carrier.replace(/\s+/g, '') + "_ShipmentService option[value='" + service.ShipmentService + "']").attr('disabled', 'disabled');
            });
        }, 100);
    }
    onSaveCarrierMarkup = () => {
        this.setState({ saveLoader: true });

        var carrierMarkups = [];
        var deleteUris = this.state.deleteUris;
        Object.keys(this.state.carrierMarkUpsList).map((key, index) => {
            var nullShipmentServiceCounter = 0;

            for (let i = 0; i < this.state.carrierMarkUpsList[key].length; i++) {
                const element = this.state.carrierMarkUpsList[key][i];

                if (!element.ShipmentService) {
                    nullShipmentServiceCounter = nullShipmentServiceCounter + 1;
                }
                if (nullShipmentServiceCounter == 1 && (parseFloat(element.FlatRateMarkup) || parseFloat(element.PercentMarkup))) {
                    carrierMarkups.push(element);
                }
                if (element.CarrierMarkupUri && !parseFloat(element.FlatRateMarkup) && !parseFloat(element.PercentMarkup)) {
                    deleteUris.push(element.CarrierMarkupUri);
                }
            }

        });
        // set object of markUp list in group by carrier
        // console.log("GroupedData",_.groupBy(carrierMarkups, function (markup) { return markup.Carrier; }));

        var payloads = {
            "DeleteUris": deleteUris,
            "CarrierMarkups": carrierMarkups
        };
        carrierService.saveCarrierMarkup(payloads).then((response) => {
            GlobalService.notification('success', this.translate('Label_Carrier_Markup_Success'));
            this.props.onRefreshGridData();
            setTimeout(() => {
                this.props.closeCarrierMarkup();
            }, 10);
        }).finally(() => {
            this.setState({ saveLoader: false });
        });
    }
    getCarrierImage = (tmpSelectedCarrier) => {
        let currentCarrierImgUrl = '3PL_Logo_Orange.png';
        tmpSelectedCarrier = tmpSelectedCarrier.toLowerCase();

        if (tmpSelectedCarrier == 'Endicia'.toLowerCase()) {
            currentCarrierImgUrl = 'endicia-logo.png';
            // currentLoginLabel = 'Endicia Account';
        } else if (tmpSelectedCarrier == 'FedEx'.toLowerCase() || tmpSelectedCarrier == 'FedEx SmartPost'.toLowerCase()) {
            currentCarrierImgUrl = 'FedEx-logo.png';
        } else if (tmpSelectedCarrier == 'Stamps.com'.toLowerCase()) {
            currentCarrierImgUrl = 'Stamps.com-logo.png';
            // currentLoginLabel = 'Stamps Account';
        } else if (tmpSelectedCarrier == 'APC'.toLowerCase()) {
            currentCarrierImgUrl = 'APC-logo.png';
        } else if (tmpSelectedCarrier == 'UPS'.toLowerCase() || tmpSelectedCarrier == 'Ups Mail Innovations'.toLowerCase() || tmpSelectedCarrier == 'UPS SurePost'.toLowerCase()) {
            currentCarrierImgUrl = 'UPS-logo.png';
        } else if (tmpSelectedCarrier == 'DHL eCommerce'.toLowerCase()) {
            currentCarrierImgUrl = 'dhl-ecommerce.png';
        } else if (tmpSelectedCarrier == 'DHL Express'.toLowerCase()) {
            currentCarrierImgUrl = 'dhl-express.png';
        } else if (tmpSelectedCarrier == 'Australia Post'.toLowerCase()) {
            currentCarrierImgUrl = 'australia-post.png';
        } else if (tmpSelectedCarrier == 'Purolator'.toLowerCase()) {
            currentCarrierImgUrl = 'purolator-seeklogo.com.png';
        } else if (tmpSelectedCarrier == 'DHL Express Australia'.toLowerCase()) {
            currentCarrierImgUrl = 'DHLExpressAU.png';
        } else if (tmpSelectedCarrier == 'Firstmile'.toLowerCase()) {
            currentCarrierImgUrl = 'First Mile.png';
        } else if (tmpSelectedCarrier == 'Globegistics'.toLowerCase()) {
            currentCarrierImgUrl = 'Globegistics.png';
        } else if (tmpSelectedCarrier == 'RR Donnelley'.toLowerCase()) {
            currentCarrierImgUrl = 'rrDonnelley.png';
        } else if (tmpSelectedCarrier == 'Canada Post'.toLowerCase()) {
            currentCarrierImgUrl = 'canada-post-logo.jpg';
        } else if (tmpSelectedCarrier == 'OnTrac'.toLowerCase()) {
            currentCarrierImgUrl = 'ontrac.png';
        } else if (tmpSelectedCarrier == 'SEKO'.toLowerCase()) {
            currentCarrierImgUrl = 'seko.png';
        } else if (tmpSelectedCarrier == 'Amazon Buy'.toLowerCase()) {
            currentCarrierImgUrl = 'amazon.png';
        } else if (tmpSelectedCarrier == 'Canpar'.toLowerCase()) {
            currentCarrierImgUrl = 'canpar.png';
        } else if (tmpSelectedCarrier == 'PARCLL'.toLowerCase()) {
            currentCarrierImgUrl = 'parcll-logo.png';
        } else if (tmpSelectedCarrier == 'ePost Global'.toLowerCase()) {
            currentCarrierImgUrl = 'ePost-global.png';
        } else if (tmpSelectedCarrier == 'Landmark'.toLowerCase()) {
            currentCarrierImgUrl = 'landmark.png';
        } else if (tmpSelectedCarrier == 'Stallion Express'.toLowerCase()) {
            currentCarrierImgUrl = 'StallionExpressLogo.png';
        } else if (tmpSelectedCarrier == 'Buku'.toLowerCase()) {
            currentCarrierImgUrl = 'buku-ship.png';
        } else if (tmpSelectedCarrier == 'PitneyBowesExp'.toLowerCase() || tmpSelectedCarrier == 'PitneyBowesStd'.toLowerCase() || tmpSelectedCarrier == 'Pitney Bowes Cross-Border'.toLowerCase()) {
            currentCarrierImgUrl = 'pitney-bowes-standard-logo.png';
        }

        return currentCarrierImgUrl;
    }

    render() {
        return (

            <WmsModel
                id={'carrierMarkUp'}
                title={this.translate('Label_Carrier_Markup')}
                width={800}
                height={703}
                class='carrier-markup-model-wrapper'
                onCloseFunc={this.props.closeCarrierMarkup}
                isOpen={this.props.isOpenCarrierMarkupModel}
                customClass='wms-model-new-ui-wrapper'>
                <div className="model-content-wrapper">
                    <WmsCollapse
                        id={'carrierMarkUp'}
                        headerLabel={this.translate("Label_Header_Markup")}
                        showCollapseText={false}
                        showCollapseIcon={false} >
                        {Object.keys(this.state.carrierMarkUpsList).map((key, index) => (

                            <div className="service-rate-options" key={"serviceOption_" + index + "_key"}>
                                <div className="carrier-image-section">
                                    <img src={'assets/images/' + this.getCarrierImage(key)} />
                                </div>
                                <div className="carrier-markup-section">
                                    {this.state.carrierMarkUpsList[key].map((obj, index) => (
                                        <div className="markup-item" key={key + "_serviceList_" + index}>
                                            <WmsDropdown
                                                id={key.replace(/\s+/g, '') + "_ShipmentService"}
                                                name="ShipmentService"
                                                label={index == 0 ? this.translate('Label_Default_Markup')+" "+this.state.carrierMarkUpsList[key][index].Carrier : this.translate('Label_Markup_Service_Level_Override')}
                                                wmsSelector={this.props.selector + "carrierServices"}
                                                onChangeFunc={this.handleChangeEvent(key, index)}
                                                value={this.state.carrierMarkUpsList[key][index].ShipmentService}
                                                options={(index == 0 ? [] : this.props.carrierShipmentServices[key.toLowerCase()] ? this.props.carrierShipmentServices[key.toLowerCase()] : [])}
                                                valueField='Description'
                                                textField='Description'
                                                extraWrapperClass={(index == 0 ? 'disable-shipment-service ' : '') + "carrier-services"}
                                                disabled={index == 0 ? true : false}
                                                required={index == 0 ? false : true}
                                                blankFirstOptionText={index == 0 ? this.translate("Label_All_Services") : ''}
                                                blankFirstOption={true} />

                                            <WmsInput
                                                id={key.replace(/\s+/g, '') + "_PercentMarkup" + index}
                                                inputName="PercentMarkup"
                                                wmsSelector={this.props.selector + "PercentMarkup"}
                                                onChangeFunc={this.handleChangeEvent(key, index)}
                                                label={this.translate("Label_Markup_Percent")}
                                                value={this.state.carrierMarkUpsList[key][index].PercentMarkup}
                                                extraWrapperClass="default-rate-percentage"
                                                required={index == 0 || this.state.carrierMarkUpsList[key][index].FlatRateMarkup ? false : true}
                                                regularExp={/^[+-]?([0-9]+([.]?)(\d?\d)?)$/} />

                                            <WmsInput
                                                id={key.replace(/\s+/g, '') + "_FlatRateMarkup" + index}
                                                inputName="FlatRateMarkup"
                                                wmsSelector={this.props.selector + "FlatRateMarkup"}
                                                onChangeFunc={this.handleChangeEvent(key, index)}
                                                label={this.translate("Label_Markup_Flat_Rate")}
                                                value={this.state.carrierMarkUpsList[key][index].FlatRateMarkup}
                                                extraWrapperClass="default-rate-amount"
                                                required={index == 0 || this.state.carrierMarkUpsList[key][index].PercentMarkup ? false : true}
                                                regularExp={/^[+-]?([0-9]+([.]?)(\d?\d)?)$/} />

                                            {index == 0 && (
                                                <WmsButton
                                                    label={''}
                                                    wmsSelector={this.props.selector + 'addServices'}
                                                    onClickFunc={this.manageCarrierMarkUp(key)}
                                                    icon='fa-plus' />
                                            )}

                                            {index != 0 && (
                                                <WmsButton
                                                    label={''}
                                                    buttonType='red'
                                                    wmsSelector={this.props.selector + 'removeServices'}
                                                    onClickFunc={this.manageCarrierMarkUp(key, index)}
                                                    disabled={this.state.carrierMarkUpsList.length == 1}
                                                    icon='fa-minus' />
                                            )}

                                        </div>
                                    ))}
                                </div>

                            </div>
                        ))}
                    </WmsCollapse>
                </div>

                <div className="footer-btn-holder">
                    <WmsButton
                        label={this.translate('Label_Cancel')}
                        wmsSelector={this.selector + "cancel"}
                        onClickFunc={this.props.closeCarrierMarkup}
                        icon="fa-ban"
                        buttonType="orange" />
                    <WmsButton
                        label={this.translate('Label_Save')}
                        wmsSelector={this.selector + 'Save'}
                        onClickFunc={this.onSaveCarrierMarkup}
                        loading={this.state.saveLoader}
                        showToolTip={this.state.isSaveDisabled}
                        tooltipText={this.state.disableSaveText}
                        disabled={this.state.isSaveDisabled}
                        icon="fa-floppy-o" />
                </div>
            </WmsModel>
        );
    }
}