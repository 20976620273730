import React from 'react';
import { StringWms } from '../../../assets/ts/StringWms.ts';

//component
import WmsInput from './../components/WmsInput/WmsInput.jsx';
import WmsButton from '../components/WmsButton/WmsButton.jsx';
import WmsCheckBox from './../components/WmsCheckBox/WmsCheckBox.jsx';
import WmsDropdown from './../components/WmsDropdown/WmsDropdown.jsx';
import WmsFormGrid from './../components/WmsFormGrid/WmsFormGrid.jsx';
import WmsCollapse from './../components/WmsCollapse/WmsCollapse.jsx';
import WmsConfirmModel from './../components/WmsConfirmModel/WmsConfirmModel.jsx';
import WmsContainer from './../components/WmsContainer/WmsContainer.jsx';
import { ObjectWms } from '../../../assets/ts/ObjectWms.ts';
import { ItemsPreferredLocationModel } from './items-preferred-location-model.component.jsx';
import WmsModel from './../components/WmsModel/WmsModel.jsx';

import * as GlobalService from './../global_services';
import * as itemService from './items.services.jsx';

export class ItemsFormBasic extends React.Component {

    constructor(props) {

        super(props);

        this.translate = window.translate;

        this.state = {
            selector: 'Item',
            customLabels: GlobalService.getCustomLabels(),
            itemQualifierExist: false,
            movablelocationZoneExist: false,
            locationZoneExist: false,
            overAllocLocationZoneExist: false,
            movablelocationZone: '',
            overAllocLocationZone: '',
            locationZone: '',
            itemQualifier: '',
            isOpenPreferredLocationModel: false,
            itemQualifierRemoveRow:'',
            isOpenQualierRemoveConfirm:false,
            qualierConfirmMsg:'',
            sharedCustomerFacilities: [],
            materialFlagOptions: StringWms.convertSringToOption(this.translate('Options_MaterialFlag')),
            packagingGroupOptions: StringWms.convertSringToOption(this.translate('Options_PackingGroup')),
            tplSettings: GlobalService.getTplSettings(),
            optionsOutboundSerialization: StringWms.convertSringToOption(this.translate('Options_Outbound_MobileSerialization'))
        }
    }

    //Add Grid Item
    addGridItem = (param_GridData_Property_Name, param_Grid_Input_Name) => {

        if (this.state[param_Grid_Input_Name] != '' && this.state[param_Grid_Input_Name] != null) {
            let itemsObj = {};
            if (param_GridData_Property_Name == 'ItemQualifiers') {

                let tmpItemQualifier = Object.assign([], this.props.sharedItem.ItemBasics['ItemQualifiers']);
                tmpItemQualifier.push({ 'Name': this.state[param_Grid_Input_Name] });

                itemsObj = Object.assign(
                    {},
                    this.props.sharedItem,
                    {
                        ItemBasics: Object.assign(
                            {},
                            this.props.sharedItem.ItemBasics,
                            { 'ItemQualifiers': tmpItemQualifier }
                        )
                    }
                );

            } else {
                let tmpData = Object.assign([], this.props.sharedItem.ItemBasics.DirectedPutawayOptions[param_GridData_Property_Name]);
                tmpData.push({ 'Name': this.state[param_Grid_Input_Name] });

                itemsObj = Object.assign(
                    {},
                    this.props.sharedItem,
                    {
                        ItemBasics: Object.assign(
                            {},
                            this.props.sharedItem.ItemBasics,
                            {
                                DirectedPutawayOptions: Object.assign(
                                    {},
                                    this.props.sharedItem.ItemBasics.DirectedPutawayOptions,
                                    {
                                        [param_GridData_Property_Name]: tmpData
                                    })
                            }
                        )
                    }
                );

                // propItemObj.ItemBasics.DirectedPutawayOptions[param_GridData_Property_Name].push({ 'Name': this.state[param_Grid_Input_Name] });
            }

            this.props.onItemDataset(itemsObj);
        }
        this.setState({ [param_Grid_Input_Name]: '' });
    }

    //Remove Grid Item
    removeGridItem = (param_CurrentGrid_Obj) => {
        let localGrid = $('#' + param_CurrentGrid_Obj._gridId).data('kendoGrid');
        localGrid.dataSource.remove(param_CurrentGrid_Obj.getItem(param_CurrentGrid_Obj.getKeys()));

        let propItemObj = this.props.sharedItem;
        if (param_CurrentGrid_Obj._gridId == 'ItemQualifiers') {
            propItemObj.ItemBasics['ItemQualifiers'] = localGrid.dataSource.view();
        } else {
            propItemObj.ItemBasics.DirectedPutawayOptions[param_CurrentGrid_Obj._gridId] = localGrid.dataSource.view();
        }

        this.props.onItemDataset(propItemObj);
    }

    //contextmenu click event for all grid
    clickEventOfContextMenu = (obj, param_CurrentGrid_Obj) => {
        if (obj.name == 'Delete') {
            this.removeGridItem(param_CurrentGrid_Obj);
        }
    }

    //Disable add zone location button when zone already exist
    handleGridInputChange = (gridId, event) => {

        let index = 0;
        let datasource = $('#' + gridId).data('kendoGrid').dataSource.view();
        index = datasource.filter((el) => {
            return el['Name'].toLowerCase() == event.target.value.toLowerCase();
        });
        this.setState({
            [event.target.name + 'Exist']: index.length > 0 ? true : false,
            [event.target.name]: event.target.value
        });
    }

    handleChange = (param_parent_property, event) => {

        let parentObj = Object.assign({}, this.props.sharedItem.ItemBasics[param_parent_property]);

        if (event.target.type == 'checkbox') {
            parentObj[event.target.name] = !this.props.sharedItem.ItemBasics[param_parent_property][event.target.name];
        } else {
            parentObj[event.target.name] = event.target.value;
        }

        if(event.target.name === "SerialTracking" && event.target.value == "0") {
            // clear SerialTracking Require Scan at Pick checkbox
            parentObj['PickSerialNumberRequired'] = false;
        }
        if(event.target.name === "ExpirationTracking" && event.target.value == "0") {
            // clear ExpirationTracking Require Scan at Pick checkbox
            parentObj['PickExpirationDateRequired'] = false;
        }
        if(event.target.name === "LotTracking" && event.target.value == "0") {
            // clear LotTracking Require Scan at Pick checkbox
            parentObj['PickLotNumberRequired'] = false;
        }
        if (param_parent_property == "HazardousSubstanceInformation") {
            if (event.target.type == 'select-one' || event.target.type == 'text')
                if (event.target.value.length != 0) {
                    parentObj["HazMat"] = true;
                } else {
                    parentObj["HazMat"] = false;
                }

        }
        if (param_parent_property == "ShippingDocumentation" && event.target.name == "Nmfc") {
            var currNmfc = event.target.value;
            var nmfcData = this.props.sharedItemOptions.NmfcOptions.find((element) => {
                if (element.NMFC == currNmfc) {
                    return element;
                }
            });
            if(nmfcData){
                parentObj['NMFCClass'] = nmfcData.Class;
                parentObj['NMFCDescription'] = nmfcData.CommodityDescription;
            }
        }

        let itemsObj = Object.assign(
            this.props.sharedItem,
            {
                ItemBasics: Object.assign(
                    {},
                    this.props.sharedItem.ItemBasics,
                    {
                        [param_parent_property]: parentObj
                    }
                )
            }
        );
        this.props.onItemDataset(itemsObj);
    }

    handleChangeSku = (event) => {
        let itemsObj = Object.assign({}, this.props.sharedItem);
        itemsObj['Sku'] = event.target.value;
        this.props.onItemDataset(itemsObj);
    }

    openItemPreferredLocationModal = () => {

        let allFacilities = GlobalService.getFacilityList(false);
        let temp = [];

        for (var x = 0; x < this.props.sharedCurrentCustomerObj.facilities.length; x++) {

            let columnIndex = ObjectWms.FindIndexByProperty(
                allFacilities,
                "id",
                this.props.sharedCurrentCustomerObj.facilities[x]
            );
            if (columnIndex != -1) {
                temp.push(allFacilities[columnIndex]);
            }
        }

        temp.unshift({ "id": 0, "name": "Unspecified" });
        this.setState({
            sharedCustomerFacilities: temp,
            isOpenPreferredLocationModel: true
        });
    }

    onPreferredLocationClose = () => {

        this.setState({
            isOpenPreferredLocationModel: false
        });

    }

    clearPreferredLocation = () => {

        let propItemObj = this.props.sharedItem;
        propItemObj.ItemBasics.DirectedPutawayOptions['PreferredLocation'] = null;
        this.props.onItemDataset(propItemObj);
        this.setState({ preferedLocationId: 0 });
    }

    onGridEdit = (event) => {
        var validator = event.container.data('kendoValidator');
        validator.options.messages.custom = "<p>"+this.translate('Label_Qualifier_Exist_Warning')+"</p>";
        let inputName = 'Name';
        var that = this;
        validator.options.rules.custom = function (input) {
            if (input.is('[name="' + inputName + '"]')) {
                let index = 0;
                let gridData = Object.assign([],that.props.sharedItem.ItemBasics.ItemQualifiers);
                index = gridData.filter((el,index) => {
                    return (
                        el['Name'].toLowerCase() == input.val().toLowerCase() &&
                        $(event.container).closest('tr').index() != index
                    );
                });
                if(index.length > 0){
                    return false;
                }
                return true;
            }
        }
        return true;
    }

    qualifierGridDataChange = (event) => {
        let gridData = event.sender.dataSource.data();
        let itemsObj = Object.assign(
            this.props.sharedItem,
            {
                ItemBasics: Object.assign(
                    {},
                    this.props.sharedItem.ItemBasics,
                    {
                        ItemQualifiers: gridData
                    }
                )
            }
        );
        setTimeout(() => {
            this.props.onItemDataset(itemsObj);
        }, 100);
    }
    openQualifierConfirm = (e) => {
        e.preventDefault();
        var rowindex = $(e.currentTarget).closest("tr").index();
        var qualifierName = ' ?';
        if(this.props.sharedItem.ItemBasics.ItemQualifiers[rowindex]){
            qualifierName = ":'" + this.props.sharedItem.ItemBasics.ItemQualifiers[rowindex].Name + "' ?";
        }

        var row = $(e.currentTarget).closest("tr");
        this.setState({
            itemQualifierRemoveRow: row,
            isOpenQualierRemoveConfirm:true,
            qualierConfirmMsg : this.translate('Label_are_you_sure_remove_qualifier_filter')+ qualifierName
        });
    }

    onCloseQualifierConfirm =()=>{
        this.setState({ isOpenQualierRemoveConfirm:false });
    }

    onRemoveQualifier = () =>{
        var grid = $("#ItemQualifiers").data("kendoGrid");
        grid.removeRow(this.state.itemQualifierRemoveRow);
        this.setState({  isOpenQualierRemoveConfirm:false });
    }
    render() {
        const renderItems = this.props.sharedItem;
        return (
            <div className={"collapseItemsTabContent_wrapper"} id="collapseItemsBasics">

                <WmsCollapse
                    id={'ItemsBasicsDescription'}
                    headerLabel={this.translate("Label_Description")}
                    showCollapseText={false}
                    showCollapseIcon={false} >

                    <WmsInput
                        id='Sku'
                        inputName='Sku'
                        wmsSelector={this.state.selector + 'Sku'}
                        onChangeFunc={this.handleChangeSku}
                        label={this.state.customLabels.Label_Sku}
                        value={renderItems.Sku}
                        required={true} />

                    <WmsInput
                        id='SellPrice'
                        inputName='SellPrice'
                        wmsSelector={this.state.selector + 'SellPrice'}
                        onChangeFunc={(e) => this.handleChange('Description', e)}
                        label={this.translate('Label_Sell_Price')}
                        value={renderItems.ItemBasics.Description.SellPrice}
                        extraWrapperClass="wms-input-sell-price-box"
                        regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/} />

                    <WmsInput
                        id='ProductCost'
                        inputName='ProductCost'
                        wmsSelector={this.state.selector + 'ProductCost'}
                        onChangeFunc={(e) => this.handleChange('Description', e)}
                        label={this.translate('Label_Product_Cost')}
                        value={renderItems.ItemBasics.Description.ProductCost}
                        extraWrapperClass="wms-input-sell-price-box"
                        regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/} />

                    <WmsInput
                        id='ProductDescription'
                        inputName='ProductDescription'
                        wmsSelector={this.state.selector + 'ProductDescription'}
                        onChangeFunc={(e) => this.handleChange('Description', e)}
                        label={this.translate('Label_Product_Description')}
                        value={renderItems.ItemBasics.Description.ProductDescription}
                        extraWrapperClass="wms-input-description-box"
                        required={true} />

                    <WmsInput
                        id='SecondaryDescription'
                        inputName='SecondaryDescription'
                        wmsSelector={this.state.selector + 'SecondaryDescription'}
                        onChangeFunc={(e) => this.handleChange('Description', e)}
                        label={this.translate('Label_Secondary_Item_Description')}
                        extraWrapperClass="wms-input-description-box"
                        value={renderItems.ItemBasics.Description.SecondaryDescription} />

                    {this.state.tplSettings.Items.ItemClassificationEnabled && <WmsDropdown
                        id="Classification"
                        name="Classification"
                        label={this.translate('Label_Classification')}
                        wmsSelector={this.state.selector + "ClassificationOptions"}
                        onChangeFunc={(e) => this.handleChange('Description', e)}
                        value={renderItems.ItemBasics.Description.Classification == null ? 'Unspecified' : renderItems.ItemBasics.Description.Classification}
                        options={this.props.sharedItemOptions.ClassificationOptions}
                        valueField='Name'
                        textField='Name'
                        parentClassName='form_group'
                        blankFirstOption={true}
                        blankFirstOptionText="Unspecified"
                        extraWrapperClass="wms-dropdown-ification-box" />}

                </WmsCollapse>

                <WmsCollapse
                    id={'ItemsBasicsInventoryOptions'}
                    headerLabel={this.translate("Label_Inventory_Options")}
                    showCollapseText={false}
                    showCollapseIcon={false} >
                    <div className="wms-allocation-main">
                        <WmsDropdown
                            id="AllocationMethod"
                            name="AllocationMethod"
                            label={this.translate('Label_Allocation_Method')}
                            wmsSelector={this.state.selector + "AllocationOptions"}
                            onChangeFunc={(e) => this.handleChange('InventoryOptions', e)}
                            value={renderItems.ItemBasics.InventoryOptions.AllocationMethod == null ? 0 : renderItems.ItemBasics.InventoryOptions.AllocationMethod}
                            options={this.props.sharedItemOptions.AllocationOptions}
                            extraWrapperClass="wms-dropdown-allocation-method-box"
                            valueField='Value'
                            textField='Name'
                            parentClassName='form_group' />

                        <WmsInput
                            id='MinOnHand'
                            inputName='MinOnHand'
                            wmsSelector={this.state.selector + 'MinOnHand'}
                            onChangeFunc={(e) => this.handleChange('InventoryOptions', e)}
                            label={this.translate('Label_Min_on_Hand')}
                            value={renderItems.ItemBasics.InventoryOptions.MinOnHand}
                            extraWrapperClass="wms-input-min-on-hand-box"
                            regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/} />

                        <WmsInput
                            id='MaxOnHand'
                            inputName='MaxOnHand'
                            wmsSelector={this.state.selector + 'MaxOnHand'}
                            onChangeFunc={(e) => this.handleChange('InventoryOptions', e)}
                            label={this.translate('Label_Max_on_Hand')}
                            value={renderItems.ItemBasics.InventoryOptions.MaxOnHand}
                            extraWrapperClass="wms-input-min-on-hand-box"
                            regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/} />

                        <WmsInput
                            id='StorageTemperature'
                            inputName='StorageTemperature'
                            wmsSelector={this.state.selector + 'StorageTemperature'}
                            onChangeFunc={(e) => this.handleChange('InventoryOptions', e)}
                            label={this.translate('Label_Storage_Temp')}
                            value={renderItems.ItemBasics.InventoryOptions.StorageTemperature}
                            extraWrapperClass="wms-input-storage-temperature-box"
                            regularExp={/^-?[0-9]*(?:\.[0-9]{0,4})?$/} />
                    </div>
                    <div className="wms-inventory-options-block">
                        <WmsInput
                            id='ReorderQuantity'
                            inputName='ReorderQuantity'
                            wmsSelector={this.state.selector + 'ReorderQuantity'}
                            onChangeFunc={(e) => this.handleChange('InventoryOptions', e)}
                            label={this.translate('Label_Reorder_Quantity')}
                            value={renderItems.ItemBasics.InventoryOptions.ReorderQuantity}
                            extraWrapperClass="wms-input-min-on-hand-box"
                            regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/} />

                        <WmsInput
                            id='DaysBetweenCounts'
                            inputName='DaysBetweenCounts'
                            wmsSelector={this.state.selector + 'DaysBetweenCounts'}
                            onChangeFunc={(e) => this.handleChange('InventoryOptions', e)}
                            label={this.translate('Label_Days_Between_Counts')}
                            value={renderItems.ItemBasics.InventoryOptions.DaysBetweenCounts}
                            extraWrapperClass="wms-input-min-on-hand-box"
                            regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/} />



                        <WmsDropdown
                            id="CycleCountType"
                            name="CycleCountType"
                            label={this.translate('Label_Cycle_Count_Type')}
                            wmsSelector={this.state.selector + "CycleCountType"}
                            onChangeFunc={(e) => this.handleChange('InventoryOptions', e)}
                            value={renderItems.ItemBasics.InventoryOptions.CycleCountType == null ? 0 : renderItems.ItemBasics.InventoryOptions.CycleCountType}
                            options={this.props.sharedItemOptions.CategoryOptions}
                            valueField='Name'
                            textField='Name'
                            extraWrapperClass="wms-dropdown-cycle-count-type-box"
                            parentClassName='form_group' />

                        <WmsCheckBox
                            id={'AutoHoldOnReceive'}
                            name={'AutoHoldOnReceive'}
                            wmsSelector={this.state.selector + 'ItemHoldStatus'}
                            onChangeFunc={(e) => this.handleChange('InventoryOptions', e)}
                            label={this.translate('Label_Receive_OntoHold')}
                            checked={renderItems.ItemBasics.InventoryOptions.AutoHoldOnReceive == true}
                            value={renderItems.ItemBasics.InventoryOptions.AutoHoldOnReceive}
                            isRounded={true} />
                    </div>
                </WmsCollapse>

                <WmsCollapse
                    id={'ItemsBasicsTrackingOptions'}
                    headerLabel={this.translate("Label_Tracking_Options")}
                    showCollapseText={false}
                    showCollapseIcon={false} >
                    <div className="wms-tracking-options-block">
                        <WmsDropdown
                            id="SerialTracking"
                            name="SerialTracking"
                            label={this.state.customLabels.Label_Serial_Number + " Tracking"}
                            wmsSelector={this.state.selector + "SerialTracking"}
                            onChangeFunc={(e) => this.handleChange('TrackingOptions', e)}
                            value={renderItems.ItemBasics.TrackingOptions.SerialTracking == null ? 0 : renderItems.ItemBasics.TrackingOptions.SerialTracking}
                            options={this.props.sharedItemOptions.SerialOptions}
                            valueField='Value'
                            extraWrapperClass="wms-dropdown-tracking-box"
                            textField='Name'
                            parentClassName='form_group' />

                        {this.state.tplSettings.Shipping.OutboundMobileSerializationEnabled &&
                            <WmsDropdown
                                id="OutboundSerialTracking"
                                name="OutboundSerialTracking"
                                label={this.translate('Label_Outbound_Mobile_Serialization')}
                                wmsSelector={this.state.selector + "OutboundSerialTracking"}
                                onChangeFunc={(e) => this.handleChange('TrackingOptions', e)}
                                value={renderItems.ItemBasics.TrackingOptions.OutboundSerialTracking}
                                options={this.state.optionsOutboundSerialization}
                                valueField='value'
                                extraWrapperClass="wms-outbound-mobile-box"
                                textField='description'
                                parentClassName='form_group'/>
                        }

                        {this.state.tplSettings.OtherUserFeatures.MobileEnabled &&
                        <WmsCheckBox
                            id={'PickSerialNumberRequired'}
                            name={'PickSerialNumberRequired'}
                            wmsSelector={this.state.selector + 'PickSerialNumberRequired'}
                            onChangeFunc={(e) => this.handleChange('TrackingOptions', e)}
                            label={this.translate('Label_Require_Scan_At_Pick')}
                            checked={renderItems.ItemBasics.TrackingOptions.PickSerialNumberRequired == true}
                            value={renderItems.ItemBasics.TrackingOptions.PickSerialNumberRequired}
                            isRounded={true}
                            disabled={(renderItems.ItemBasics.TrackingOptions.SerialTracking && renderItems.ItemBasics.TrackingOptions.SerialTracking != "0" ) ? false : true}
                            parentClassName={(renderItems.ItemBasics.TrackingOptions.SerialTracking && renderItems.ItemBasics.TrackingOptions.SerialTracking != "0" ) ? '' : 'wms-check-box-disable'} />
                        }
                    </div>
                    <div className="wms-tracking-options-block">
                        <WmsDropdown
                            id="ExpirationTracking"
                            name="ExpirationTracking"
                            label={this.state.customLabels.Label_Expiration_Date + " Tracking"}
                            wmsSelector={this.state.selector + "ExpirationTracking"}
                            onChangeFunc={(e) => this.handleChange('TrackingOptions', e)}
                            value={renderItems.ItemBasics.TrackingOptions.ExpirationTracking == null ? 0 : renderItems.ItemBasics.TrackingOptions.ExpirationTracking}
                            options={this.props.sharedItemOptions.ExpirationOptions}
                            valueField='Value'
                            extraWrapperClass="wms-dropdown-tracking-box"
                            textField='Name'
                            parentClassName='form_group' />

                        <WmsInput
                            id='AutoHoldExpirationDaysThreshold'
                            inputName='AutoHoldExpirationDaysThreshold'
                            wmsSelector={this.state.selector + 'HoldThresholdDays'}
                            onChangeFunc={(e) => this.handleChange('TrackingOptions', e)}
                            label={this.translate('Label_Hold_Threshold_Days')}
                            value={renderItems.ItemBasics.TrackingOptions.AutoHoldExpirationDaysThreshold}
                            regularExp={/^\w+( \w+)*$/}
                            extraWrapperClass={(renderItems.ItemBasics.TrackingOptions.ExpirationTracking && renderItems.ItemBasics.TrackingOptions.ExpirationTracking != "0") ? '' : 'wms-input-disable'}
                            disabled={(renderItems.ItemBasics.TrackingOptions.ExpirationTracking && renderItems.ItemBasics.TrackingOptions.ExpirationTracking != "0") ? false : true}
                            zeroBasedCondition={2} />

                        {this.state.tplSettings.OtherUserFeatures.MobileEnabled &&
                        <WmsCheckBox
                            id={'PickExpirationDateRequired'}
                            name={'PickExpirationDateRequired'}
                            wmsSelector={this.state.selector + 'PickExpirationDateRequired'}
                            onChangeFunc={(e) => this.handleChange('TrackingOptions', e)}
                            label={this.translate('Label_Require_Scan_At_Pick')}
                            checked={renderItems.ItemBasics.TrackingOptions.PickExpirationDateRequired == true}
                            value={renderItems.ItemBasics.TrackingOptions.PickExpirationDateRequired}
                            isRounded={true}
                            disabled={(renderItems.ItemBasics.TrackingOptions.ExpirationTracking && renderItems.ItemBasics.TrackingOptions.ExpirationTracking != "0" ) ? false : true}
                            parentClassName={(renderItems.ItemBasics.TrackingOptions.ExpirationTracking && renderItems.ItemBasics.TrackingOptions.ExpirationTracking != "0" ) ? '' : 'wms-check-box-disable'} />
                        }
                    </div>
                    <div className="wms-tracking-options-block">
                        <WmsDropdown
                            id="LotTracking"
                            name="LotTracking"
                            label={this.state.customLabels.Label_Lot_Number + " Tracking"}
                            wmsSelector={this.state.selector + "LotTracking"}
                            onChangeFunc={(e) => this.handleChange('TrackingOptions', e)}
                            value={renderItems.ItemBasics.TrackingOptions.LotTracking == null ? 0 : renderItems.ItemBasics.TrackingOptions.LotTracking}
                            options={this.props.sharedItemOptions.LotOptions}
                            valueField='Value'
                            extraWrapperClass="wms-dropdown-tracking-box"
                            textField='Name'
                            parentClassName='form_group' />

                        {this.state.tplSettings.OtherUserFeatures.MobileEnabled &&
                        <WmsCheckBox
                            id={'PickLotNumberRequired'}
                            name={'PickLotNumberRequired'}
                            wmsSelector={this.state.selector + 'PickLotNumberRequired'}
                            onChangeFunc={(e) => this.handleChange('TrackingOptions', e)}
                            label={this.translate('Label_Require_Scan_At_Pick')}
                            checked={renderItems.ItemBasics.TrackingOptions.PickLotNumberRequired == true}
                            value={renderItems.ItemBasics.TrackingOptions.PickLotNumberRequired}
                            isRounded={true}
                            disabled={(renderItems.ItemBasics.TrackingOptions.LotTracking && renderItems.ItemBasics.TrackingOptions.LotTracking != "0" ) ? false : true}
                            parentClassName={(renderItems.ItemBasics.TrackingOptions.LotTracking && renderItems.ItemBasics.TrackingOptions.LotTracking != "0" ) ? '' : 'wms-check-box-disable'} />
                        }

                    </div>
                    <div className="wms-tracking-options-block">
                        <WmsDropdown
                            id="CostTracking"
                            name="CostTracking"
                            label={this.translate('Label_Cost_Tracking')}
                            wmsSelector={this.state.selector + "CostTracking"}
                            onChangeFunc={(e) => this.handleChange('TrackingOptions', e)}
                            value={renderItems.ItemBasics.TrackingOptions.CostTracking == null ? 0 : renderItems.ItemBasics.TrackingOptions.CostTracking}
                            options={this.props.sharedItemOptions.CostOptions}
                            extraWrapperClass="wms-dropdown-tracking-box"
                            valueField='Value'
                            textField='Name'
                            parentClassName='form_group' />
                    </div>
                </WmsCollapse>

                <WmsCollapse
                    id={'ItemsBasicsTrackingOptions'}
                    headerLabel={this.translate("Label_Shipping_Documentation")}
                    showCollapseText={false}
                    showCollapseIcon={false} >
                    <div className="wms-tracking-options-block">
                        <WmsDropdown
                            id="CountryOfOrigin"
                            name="CountryOfOrigin"
                            label={this.translate('Label_Country_Of_Origin')}
                            wmsSelector={this.state.selector + "CountryOfOrigin"}
                            onChangeFunc={(e) => this.handleChange('ShippingDocumentation', e)}
                            value={renderItems.ItemBasics.ShippingDocumentation.CountryOfOrigin == null ? "" : renderItems.ItemBasics.ShippingDocumentation.CountryOfOrigin}
                            options={this.props.sharedItemOptions.CountryOptions}
                            valueField="Value"
                            nestedValueField="CountryCode"
                            textField='Name'
                            parentClassName='form_group'
                            blankFirstOption={true}
                            extraWrapperClass="wms-dropdown-shipping-documentation-box"
                            blankFirstOptionText="Unspecified" />
                    </div>
                    <div className="wms-tracking-options-block">
                        <WmsDropdown
                            id="Nmfc"
                            name="Nmfc"
                            label={this.translate('Label_NMFC')}
                            wmsSelector={this.state.selector + "NmfcOptions"}
                            onChangeFunc={(e) => this.handleChange('ShippingDocumentation', e)}
                            value={renderItems.ItemBasics.ShippingDocumentation.Nmfc == null ? 'None' : renderItems.ItemBasics.ShippingDocumentation.Nmfc}
                            options={this.props.sharedItemOptions.NmfcOptions}
                            valueField='NMFC'
                            extraWrapperClass="wms-dropdown-shipping-nmfc-box"
                            textField='NMFC'
                            blankFirstOption={true}
                            blankFirstOptionText={"None"}
                            parentClassName='form_group' />
                        <WmsInput
                            id="NMFCClass"
                            inputName="NMFCClass"
                            label={this.translate("Label_NMFC_Class")}
                            wmsSelector={this.state.selector + "NMFCClass"}
                            onChangeFunc={(e) => function () { }}
                            value={renderItems.ItemBasics.ShippingDocumentation.NMFCClass}
                            extraWrapperClass="wms-input-harmonized-tariff-box"
                            disabled={true} />
                        <WmsInput
                            id="NMFCDescription"
                            inputName="NMFCDescription"
                            label={this.translate("Label_NMFC_Description")}
                            wmsSelector={this.state.selector + "NMFCDescription"}
                            onChangeFunc={(e) => function () { }}
                            value={renderItems.ItemBasics.ShippingDocumentation.NMFCDescription}
                            extraWrapperClass="wms-input-nmfc-description-box"
                            disabled={true} />
                    </div>
                    <div className="wms-tracking-options-block">
                        <WmsInput
                            id="HarmonizedTariffCode"
                            inputName="HarmonizedTariffCode"
                            label={this.translate("Label_Harmonized_Tariff_Code")}
                            wmsSelector={this.state.selector + "HarmonizedTariffCode"}
                            onChangeFunc={(e) => this.handleChange('ShippingDocumentation', e)}
                            value={renderItems.ItemBasics.ShippingDocumentation.HarmonizedTariffCode}
                            extraWrapperClass="wms-input-harmonized-tariff-box"
                            regularExp={/^\w+([.]\w+)*[.]?$/} />
                    </div>
                </WmsCollapse>

                <WmsCollapse
                    id={'ItemsBasicsDirectedPutaway'}
                    headerLabel={this.translate("Label_Directed_Putaway")}
                    showCollapseText={false}
                    showCollapseIcon={false}
                    isExpanded={this.state.tplSettings.Receiving.DirectedPutAwayEnabled == true}
                    isShowTooltip={this.state.tplSettings.Receiving.DirectedPutAwayEnabled != true}
                    tooltipText={this.translate('Label_Directed_Putaway_DisableTooltip')}
                    >
                    <div className="wms-zones-box">
                        <div className="wms_putaway_wrap">
                            <WmsInput
                                id="movablelocationZone"
                                inputName="movablelocationZone"
                                label={this.state.customLabels.Label_Movable_Unit + " Zones"}
                                wmsSelector={this.state.selector + "MovableUnitLocationZones"}
                                onChangeFunc={(e) => this.handleGridInputChange('MovableUnitLocationZones', e)}
                                value={this.state.movablelocationZone} />

                            <WmsButton
                                label={this.translate('Label_Add_MovableUnitLocationZones')}
                                wmsSelector={this.state.selector + 'MovableUnitLocationZonesSaveBtn'}
                                onClickFunc={(e) => this.addGridItem('MovableUnitLocationZones', 'movablelocationZone')}
                                disabled={this.state.movablelocationZoneExist}
                                icon='fa-plus' />

                            <WmsFormGrid
                                id={'MovableUnitLocationZones'}
                                wmsSelector={this.state.selector + 'MovableUnitLocationZonesGrid'}
                                columns={[{ field: "Name", title: this.translate('Label_Enter_MovableUnitLocationZones') }]}
                                staticGridData={renderItems.ItemBasics.DirectedPutawayOptions.MovableUnitLocationZones}
                                windowId={this.props.windowId}
                                gridKeySelection={'Name'}
                                clickEventOfContextMenu={this.clickEventOfContextMenu}
                                parentClassName={'wms-form-grid-proto-design hidden-header'}
                                menu={
                                    [{
                                        "name": "Delete",
                                        "value": [],
                                        "disabled": false,
                                        "disabledText": "You may not add blahssss",
                                        "icon": "fa-line-chart",
                                        "color": "green",
                                        "clickFunc": ((e) => function () { })
                                    }]
                                }
                            />
                        </div>
                        <div className="wms_putaway_wrap">
                            <WmsInput
                                id="locationZone"
                                inputName="locationZone"
                                label={this.translate('Label_Enter_LocationZones')}
                                wmsSelector={this.state.selector + "LocationZones"}
                                onChangeFunc={(e) => this.handleGridInputChange('LocationZones', e)}
                                value={this.state.locationZone} />

                            <WmsButton
                                label={this.translate('Label_Add_LocationZones')}
                                wmsSelector={this.state.selector + 'LocationZonesSaveBtn'}
                                onClickFunc={(e) => this.addGridItem('LocationZones', 'locationZone')}
                                icon='fa-plus'
                                disabled={this.state.locationZoneExist} />

                            <WmsFormGrid
                                id={'LocationZones'}
                                wmsSelector={this.state.selector + 'LocationZonesGrid'}
                                columns={[{ field: "Name", title: this.translate('Label_Enter_LocationZones') }]}
                                staticGridData={renderItems.ItemBasics.DirectedPutawayOptions.LocationZones}
                                windowId={this.props.windowId}
                                gridKeySelection={'Name'}
                                clickEventOfContextMenu={this.clickEventOfContextMenu}
                                parentClassName={'wms-form-grid-proto-design hidden-header'}
                                menu={
                                    [{
                                        "name": "Delete",
                                        "value": [],
                                        "disabled": false,
                                        "disabledText": "You may not add blahssss",
                                        "icon": "fa-line-chart",
                                        "color": "green",
                                        "clickFunc": ((e) => function () { })
                                    }]
                                }
                            />
                        </div>
                        <div className="wms_putaway_wrap">
                            <WmsInput
                                id="overAllocLocationZone"
                                inputName="overAllocLocationZone"
                                label={this.translate('Label_Enter_OverAllocLocationZones')}
                                wmsSelector={this.state.selector + "OverAllocLocationZones"}
                                onChangeFunc={(e) => this.handleGridInputChange('OverAllocLocationZones', e)}
                                value={this.state.overAllocLocationZone} />

                            <WmsButton
                                label={this.translate('Label_Add_OverAllocLocationZones')}
                                wmsSelector={this.state.selector + 'OverAllocLocationZonesSaveBtn'}
                                onClickFunc={(e) => this.addGridItem('OverAllocLocationZones', 'overAllocLocationZone')}
                                icon='fa-plus'
                                disabled={this.state.overAllocLocationZoneExist} />

                            <WmsFormGrid
                                id={'OverAllocLocationZones'}
                                wmsSelector={this.state.selector + 'OverAllocLocationZonesGrid'}
                                columns={[{ field: "Name", title: this.translate('Label_Enter_OverAllocLocationZones') }]}
                                staticGridData={renderItems.ItemBasics.DirectedPutawayOptions.OverAllocLocationZones}
                                windowId={this.props.windowId}
                                gridKeySelection={'Name'}
                                clickEventOfContextMenu={this.clickEventOfContextMenu}
                                parentClassName={'wms-form-grid-proto-design hidden-header'}
                                menu={
                                    [{
                                        "name": "Delete",
                                        "value": [],
                                        "disabled": false,
                                        "disabledText": "You may not add blahssss",
                                        "icon": "fa-line-chart",
                                        "color": "green",
                                        "clickFunc": ((e) => function () { })
                                    }]
                                }
                            />
                        </div>
                    </div>
                    <div className="wms_drop_block">
                        <div className="wms_datepicker_wrap">
                            <WmsInput
                                id="LocationName"
                                inputName="LocationName"
                                label={this.translate('PreferredLocationId')}
                                wmsSelector={this.state.selector + "PreferredLocationId"}
                                onChangeFunc={(e) => this.handleChange('DirectedPutawayOptions', e)}
                                regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                                value={renderItems.ItemBasics.DirectedPutawayOptions.PreferredLocation != null ? renderItems.ItemBasics.DirectedPutawayOptions.PreferredLocation.LocationName : ''} />
                            {renderItems.ItemBasics.DirectedPutawayOptions.PreferredLocation != null && renderItems.ItemBasics.DirectedPutawayOptions.PreferredLocation.LocationName != '' &&
                                (<button
                                    className="wms-DatePickerClearer"
                                    data-wms-selector="RemovePreferredLocation"
                                    onClick={this.clearPreferredLocation}>X </button>)}

                            <WmsInput
                                id="MixedExpirationDaysThreshhold"
                                inputName="MixedExpirationDaysThreshhold"
                                label={this.translate('Label_MixedExpirationDaysThreshhold')}
                                wmsSelector={this.state.selector + "MixedExpirationDaysThreshhold"}
                                onChangeFunc={(e) => this.handleChange('DirectedPutawayOptions', e)}
                                regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                                value={renderItems.ItemBasics.DirectedPutawayOptions.MixedExpirationDaysThreshhold} />
                        </div>
                        <WmsButton
                            label={
                                renderItems.ItemBasics.DirectedPutawayOptions.PreferredLocation != null && renderItems.ItemBasics.DirectedPutawayOptions.PreferredLocation.LocationName != '' ?
                                    this.translate('Label_Modify') :
                                    this.translate('Label_Add')
                            }
                            wmsSelector={this.state.selector + 'AddPreferredLocation'}
                            onClickFunc={this.openItemPreferredLocationModal}
                            icon='fa-plus' />


                        <div className="wms_checkbox_list">
                            <WmsCheckBox
                                id={'ForceIntoPreferredLocation'}
                                name={'ForceIntoPreferredLocation'}
                                wmsSelector={this.state.selector + 'Location'}
                                onChangeFunc={(e) => this.handleChange('DirectedPutawayOptions', e)}
                                label={this.translate('Label_Preferred_Location')}
                                checked={renderItems.ItemBasics.DirectedPutawayOptions.ForceIntoPreferredLocation == true}
                                value={renderItems.ItemBasics.DirectedPutawayOptions.ForceIntoPreferredLocation}
                                isRounded={true} />

                            <WmsCheckBox
                                id={'AllowMixedItemStorage'}
                                name={'AllowMixedItemStorage'}
                                wmsSelector={this.state.selector + 'AllowMixedItemStorage'}
                                onChangeFunc={(e) => this.handleChange('DirectedPutawayOptions', e)}
                                label={this.translate('Label_Allow_Mixed_Item_Storage')}
                                checked={renderItems.ItemBasics.DirectedPutawayOptions.AllowMixedItemStorage == true}
                                value={renderItems.ItemBasics.DirectedPutawayOptions.AllowMixedItemStorage}
                                isRounded={true} />

                            <WmsCheckBox
                                id={'AllowMixedLotStorage'}
                                name={'AllowMixedLotStorage'}
                                wmsSelector={this.state.selector + 'AllowMixedLotStorage'}
                                onChangeFunc={(e) => this.handleChange('DirectedPutawayOptions', e)}
                                label={this.translate('Label_Allow_Mixed_Lot_Storage')}
                                checked={renderItems.ItemBasics.DirectedPutawayOptions.AllowMixedLotStorage == true}
                                value={renderItems.ItemBasics.DirectedPutawayOptions.AllowMixedLotStorage}
                                isRounded={true} />
                        </div>
                    </div>
                </WmsCollapse>

                {this.state.tplSettings.Items.HazMatEnabled && <WmsCollapse
                    id={'ItemsBasicsHazardousMaterial'}
                    headerLabel={this.translate("Label_Hazardous_Material")}
                    showCollapseText={false}
                    showCollapseIcon={false} >

                    <div className="wms_checkbox_wrap">
                        <WmsCheckBox
                            id={'HazMat'}
                            name={'HazMat'}
                            wmsSelector={this.state.selector + 'HazMat'}
                            onChangeFunc={(e) => this.handleChange('HazardousSubstanceInformation', e)}
                            label={this.translate('Label_Hazardous_Material')}
                            checked={renderItems.ItemBasics.HazardousSubstanceInformation.HazMat == true}
                            value={renderItems.ItemBasics.HazardousSubstanceInformation.HazMat}
                            isRounded={true} />
                    </div>

                    <WmsDropdown
                        id="MaterialFlag"
                        name="MaterialFlag"
                        label={this.translate('Label_Material_Flag')}
                        wmsSelector={this.state.selector + "MaterialFlag"}
                        onChangeFunc={(e) => this.handleChange('HazardousSubstanceInformation', e)}
                        value={renderItems.ItemBasics.HazardousSubstanceInformation.MaterialFlag == null ? "Unspecified" : renderItems.ItemBasics.HazardousSubstanceInformation.MaterialFlag}
                        options={this.state.materialFlagOptions}
                        valueField='description'
                        textField='description'
                        parentClassName='form_group'
                        blankFirstOption={true}
                        blankFirstOptionText="Unspecified" />

                    <WmsInput
                        id="Class"
                        inputName="Class"
                        label={this.translate('Label_Class')}
                        wmsSelector={this.state.selector + "Class"}
                        onChangeFunc={(e) => this.handleChange('HazardousSubstanceInformation', e)}
                        value={renderItems.ItemBasics.HazardousSubstanceInformation.Class}
                        extraWrapperClass="wms-input-class-flashpoint-box"/>

                    <WmsInput
                        id="ShippingName"
                        inputName="ShippingName"
                        label={this.translate('Label_Shipping_Name')}
                        wmsSelector={this.state.selector + "ShippingName"}
                        onChangeFunc={(e) => this.handleChange('HazardousSubstanceInformation', e)}
                        value={renderItems.ItemBasics.HazardousSubstanceInformation.ShippingName}/>

                    <WmsInput
                        id="MaterialId"
                        inputName="MaterialId"
                        label={this.translate('Label_Material_ID')}
                        wmsSelector={this.state.selector + "MaterialId"}
                        onChangeFunc={(e) => this.handleChange('HazardousSubstanceInformation', e)}
                        value={renderItems.ItemBasics.HazardousSubstanceInformation.MaterialId}/>

                    <WmsInput
                        id="LabelCode"
                        inputName="LabelCode"
                        label={this.translate('Label_Label_Code')}
                        wmsSelector={this.state.selector + "LabelCode"}
                        onChangeFunc={(e) => this.handleChange('HazardousSubstanceInformation', e)}
                        value={renderItems.ItemBasics.HazardousSubstanceInformation.LabelCode}/>

                    <WmsDropdown
                        id="PackagingGroup"
                        name="PackagingGroup"
                        label={this.translate('Label_Packaging_Group')}
                        wmsSelector={this.state.selector + "PackagingGroup"}
                        onChangeFunc={(e) => this.handleChange('HazardousSubstanceInformation', e)}
                        value={renderItems.ItemBasics.HazardousSubstanceInformation.PackagingGroup == null ? "Unspecified" : renderItems.ItemBasics.HazardousSubstanceInformation.PackagingGroup}
                        options={this.state.packagingGroupOptions}
                        valueField='description'
                        textField='description'
                        parentClassName='form_group'
                        blankFirstOption={true}
                        blankFirstOptionText="Unspecified" />

                    <WmsInput
                        id="Flashpoint"
                        inputName="Flashpoint"
                        label={this.translate('Label_Flashpoint')}
                        wmsSelector={this.state.selector + "Flashpoint"}
                        onChangeFunc={(e) => this.handleChange('HazardousSubstanceInformation', e)}
                        value={renderItems.ItemBasics.HazardousSubstanceInformation.Flashpoint}
                        extraWrapperClass="wms-input-class-flashpoint-box"/>

                </WmsCollapse>}

                <WmsCollapse
                    id={'ItemsBasicsItemQualifiers'}
                    headerLabel={this.state.customLabels.Label_Item + ' Qualifiers'}
                    showCollapseText={false}
                    showCollapseIcon={false} >
                    <div className="wms-item-qualifiers-block">
                        <WmsInput
                            id="itemQualifier"
                            inputName="itemQualifier"
                            label={this.translate('Label_Enter_Qualifiers')}
                            wmsSelector={this.state.selector + "ItemQualifier"}
                            onChangeFunc={(e) => this.handleGridInputChange('ItemQualifiers', e)}
                            value={this.state.itemQualifier} />

                        <WmsButton
                            label={this.translate('Label_Add_Qualifier')}
                            wmsSelector={this.state.selector + 'ItemQualifierSaveBtn'}
                            onClickFunc={(e) => this.addGridItem('ItemQualifiers', 'itemQualifier')}
                            disabled={this.state.itemQualifierExist || renderItems.Assembly.Components.length > 0}
                            showToolTip={true}
                            tooltipText={renderItems.Assembly.Components.length > 0 ? this.translate('Tooltip_Cannot_Add_Qualifiers') : this.translate('Label_Qualifier_Exist_Warning')}
                            icon='fa-plus' />
                    </div>

                    <WmsFormGrid
                        id={"ItemQualifiers"}
                        columns={[{
                            field: "Name",
                            title: "Name"
                        },{
                            field:'cmdRemove',
                            menu: false,
                            command: [{
                                name: "Delete",
                                text: "X",
                                className: "qualifier-delete",
                                click: this.openQualifierConfirm
                            }],
                            title: "&nbsp;",
                            width: 100,
                        }]}
                        wmsSelector={this.state.selector + 'ItemQualifierGrid'}
                        staticGridData={renderItems.ItemBasics.ItemQualifiers}
                        windowId={this.props.windowId}
                        parentClassName={'wms-form-grid-proto-design hidden-header'}
                        gridKeySelection={'Name'}
                        isEditable={{ confirmation:false }}
                        onEdit={this.onGridEdit}
                        onGridSave={this.qualifierGridDataChange}
                        onRemove={this.qualifierGridDataChange}
                    />

                    {this.state.isOpenPreferredLocationModel &&
                    <ItemsPreferredLocationModel
                        sharedIsOpenPreferredLocationModel={this.state.isOpenPreferredLocationModel}
                        sharedCustomerFacilities={this.state.sharedCustomerFacilities}
                        sharedItem={renderItems}
                        onPreferredLocationClose={this.onPreferredLocationClose}
                        onItemDataset={this.props.onItemDataset}
                    />
                    }
                </WmsCollapse>
                <WmsConfirmModel
                    id={"ConfirmUpdateModel" + this.props.windowId}
                    isOpen={this.state.isOpenQualierRemoveConfirm}
                    type={this.translate('Label_Confirmation')}
                    width={500}
                    height={175}
                    firstBtnLabel={this.translate('Label_Yes')}
                    firstBtnIcon={"fa-check-square"}
                    firstBtnOnClickFunc={this.onRemoveQualifier}
                    sawCheckBox={false}
                    conFormModelText={this.state.qualierConfirmMsg}
                    onCloseFunc={this.onCloseQualifierConfirm}
                    thirdBtnLabel={this.translate('Label_No')}
                    thirdBtnIcon={"fa-times-circle"}
                    isLoadingStop={false}
                />
            </div>

        )
    }
}