var axios = require("axios");

export const auditOptions = function (facilityUri) {
    return axios.get(`/WebUI/Inventory/ManageAudits/AuditOptions?facilityUri=${facilityUri}`).then(function (response) {
        return response;
    });
};
export const auditSearchInfo = function (searchData) {
    return axios
        .put("/WebUI/Inventory/UpdateSession/AuditSearchInfo", { "searchInfo": searchData })
        .then(function (response) {
            return response;
        });
};

export const auditJob = function (param, facilityUri, type) {
    let url = "/WebUI/inventory/editaudits/audit";
    if (type === "Create") {
        return axios.post(url, { auditView: param, facilityUri: facilityUri }).then((response: any) => {
            return response;
        });
    } else {
        return axios.put(url, param).then((response: any) => {
            return response;
        });
    }
};

export const closeJob = function (auditUris) {
    let url = "/WebUI/Inventory/ManageAudits/Close";
    return axios.put(url, { "auditUris": auditUris }).then((response: any) => {
        return response;
    });
};
